import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { List } from "linqts";

@Injectable({
  providedIn: "root",
})
export class DataTransferService<T> {

  private dataTransferObj: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
  
  get $data() {
    return this.dataTransferObj.asObservable();
  }
  setData(value: T) {
    this.dataTransferObj.next(value);
  }

  async downloadFile(dataURI:any, fileName:any) {

    let curTimeStamp = new Date().getTime();
    let fileType = "image/png";
    let fileExt = dataURI.slice(
      ((dataURI.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (fileExt.toLowerCase() == "jpg" || fileExt.toLowerCase() == "jpeg")
      fileType = "image/jpeg";
    if (fileExt.toLowerCase() == "png")
      fileType = "image/png";
    if (fileExt.toLowerCase() == "pdf")
      fileType = "application/pdf";
    if (fileExt.toLowerCase() == "xls" || fileExt.toLowerCase() == "csv")
      fileType = "application/vnd.ms-excel";
    if (fileExt.toLowerCase() == "xlsx")
      fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!fileName || fileName == null || fileName == "" || typeof fileName == undefined)
      fileName = curTimeStamp + fileExt.toLowerCase();

    dataURI = dataURI.includes("?")
      ? `${dataURI}&v=${curTimeStamp}`
      : `${dataURI}?v=${curTimeStamp}`;

    let headerFooterLogoBytes = await fetch(
      dataURI
    ).then((res) =>
      res.arrayBuffer()
    );

    const blob = new Blob([headerFooterLogoBytes], {
      type: fileType, //"application/pdf",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.style.display = "none";
    a.click();
    a.remove();

    setTimeout(() => window.URL.revokeObjectURL(url), 0);
  }  
}

declare global {
  interface Array<T> {
    ToList(): List<T>;
  }
}

Array.prototype.ToList = function () {
  return new List(this);
};
