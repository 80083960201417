<ng-container *ngIf="show">
    <div class="backdrop"></div>

    <div id="loader-wrapper">
        <div class="flex-box-spinner">
            <div id="loader"> </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
            <img [src]="'images/loader.png' | environment" class="logoimg" alt="loader.png" />
        </div>
    </div>

    <div class="full-loader-text">
        <p *ngIf="message">{{message}}</p>
    </div>
</ng-container>