/**
 * An object used to get page information from the server.
 */
export class Page {
    // The number of elements in the page
    private _size: number | any = 0;
    public get size(): number | any {
      return this._size;
    }
    public set size(value: number | any) {
      this._size = value;
    }
  
    // The current page number
    private _pageNumber: number | any = 0;
    public get pageNumber(): number | any {
      return this._pageNumber;
    }
    public set pageNumber(value: number | any) {
      this._pageNumber = value;
    }
  
    // The total number of elements
    private _totalElements: number = 0;
    public get totalElements(): number {
      return this._totalElements;
    }
    public set totalElements(value: number) {
      this._totalElements = value;
    }
  
    // The total number of pages
    private _totalPages: number = 0;
    public get totalPages(): number {
      return this._totalPages;
    }
    public set totalPages(value: number) {
      this._totalPages = value;
    }
  
    // The total number of pages
    private _serachParam: string = "";
    public get serachParam(): string {
      return this._serachParam;
    }
    public set serachParam(value: string) {
      this._serachParam = value;
    }
  
    //
    private _isDelete: boolean | any = false;
    public get isDelete(): boolean| any  {
      return this._isDelete;
    }
    public set isDelete(value: boolean| any ) {
      this._isDelete = value;
    }
  
    //isActiveFlag
    private _isActive: boolean | any = false;
    public get isActive(): boolean| any  {
      return this._isActive;
    }
    public set isActive(value: boolean| any ) {
      this._isActive = value;
    }
  
    private _flag: string = "";
    public set flag(value: string ) {
      this._flag = value;
    }
  
    private _sortColoumn: string = "";
    public get sortColoumn(): string {
      return this._sortColoumn;
    }
    public set sortColoumn(value: string) {
      this._sortColoumn = value;
    }
  
    // The total number of pages
    private _sortOrder: string = "";
    public get sortOrder(): string {
      return this._sortOrder;
    }
    public set sortOrder(value: string) {
      this._sortOrder = value;
    }
   }
  