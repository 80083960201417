import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd,
} from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "../../../services/theme.service";
import { AdminContainerClasses, ILayoutConf, LayoutService } from "../../../services/layout.service";
import { filter } from "rxjs/operators";
import { JwtAuthService } from "../../../services/auth/jwt-auth.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminLayoutComponent implements OnInit {
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub!: Subscription;
  private layoutConfSub!: Subscription;
  private routerEventSub: Subscription;

  public scrollConfig: any = {};
  public layoutConf: ILayoutConf = {};
  public adminContainerClasses: AdminContainerClasses | {} = {};

  isWarningNotification: boolean = false;
  isForceNotification: boolean = false;
  notificationDays: number = 0;

  showBackdrop: boolean = false;

  //dashboardSideNavReturnUrl: string = "/dashboard/owner";
  dashboardSideNavReturnUrl: string = "/environmental/emissions/tonnesofquantityconsumed";
  constructor(
    private router: Router,
    public translate: TranslateService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private cdr: ChangeDetectorRef,
    private jwtAuth: JwtAuthService,
    private cookieService: CookieService,
  ) {
    // Check Auth Token is valid
    this.jwtAuth.checkTokenIsValid().subscribe();

    // Close sidenav after route change in mobile
    this.routerEventSub = router.events
      .pipe(filter((event) :  event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((routeChange: NavigationEnd) => {
        this.layout.adjustLayout({ route: routeChange.url });
        this.scrollToTop();
      });

    // Translator init
    const browserLang: any = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  ngOnInit() {
    console.log('loading admin layout')
    this.isWarningNotification = this.cookieService.get("isWarningNotification") ? JSON.parse(this.cookieService.get("isWarningNotification")) : false;
    this.isForceNotification = this.cookieService.get("isForceNotification") ? JSON.parse(this.cookieService.get("isForceNotification")) : false;
    this.notificationDays = this.cookieService.get("notificationDays") ? Number(this.cookieService.get("notificationDays")) : 0;
    this.layoutConfSub = this.layout.layoutConf$.subscribe((layoutConf) => {
      this.layoutConf = layoutConf;
      this.adminContainerClasses = this.updateAdminContainerClasses(
        this.layoutConf
      );
      this.cdr.markForCheck();
    });

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe((event) => {
      if (
        event instanceof RouteConfigLoadStart ||
        event instanceof ResolveStart
      ) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
  }
  @HostListener("window:resize", ["$event"])
  onResize(event:any) {
    this.layout.adjustLayout(event);
  }

  scrollToTop() {
    if (document) {
      setTimeout(() => {
        let element;
        if (this.layoutConf.topbarFixed) {
          element = <HTMLElement>(
            document.querySelector("#rightside-content-hold")
          );
        } else {
          element = <HTMLElement>document.querySelector("#main-content-wrap");
        }
        element.scrollTop = 0;
      });
    }
  }
  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  sidebarMouseenter(e:any) {
    if (this.layoutConf.sidebarStyle === "compact") {
      this.layout.publishLayoutChange(
        { sidebarStyle: "full" },
        { transitionClass: true }
      );
    }
  }

  sidebarMouseleave(e:any) {
    if (
      this.layoutConf.sidebarStyle === "full" &&
      this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange(
        { sidebarStyle: "compact" },
        { transitionClass: true }
      );
    }
  }

  updateAdminContainerClasses(layoutConf: ILayoutConf): AdminContainerClasses {
    return {
      "navigation-top": layoutConf.navigationPos === "top",
      "sidebar-full": layoutConf.sidebarStyle === "full",
      "sidebar-compact":
        layoutConf.sidebarStyle === "compact" &&
        layoutConf.navigationPos === "top",
      "compact-toggle-active": layoutConf.sidebarCompactToggle || false,
      "sidebar-compact-big":
        layoutConf.sidebarStyle === "compact-big" &&
        layoutConf.navigationPos === "top",
      "sidebar-opened":
        layoutConf.sidebarStyle !== "closed" &&
        layoutConf.navigationPos === "top",
      "sidebar-closed": layoutConf.sidebarStyle === "closed",
      "fixed-topbar":
        (layoutConf.topbarFixed && layoutConf.navigationPos === "top") || false,
    };
  }
  closeNotification() {
    this.isWarningNotification = false;
    this.isForceNotification = false;
  }
  navigateToURL() {
    this.isWarningNotification = false;
    this.isForceNotification = false;
    this.router.navigateByUrl('/dashboard/user-profile', {
      state: {
        changepassword: true
      },
    });
  }

  closeDashboardSideNav() {
    this.router.navigateByUrl(this.dashboardSideNavReturnUrl);
  }

  getDashboardSideNavBackLink(){
    if(this.router.getCurrentNavigation()){
      const state = this.router.getCurrentNavigation()?.extras?.state;
      if(state && state['returnUrl']){
        this.dashboardSideNavReturnUrl = state['returnUrl'];
      } else {
        this.dashboardSideNavReturnUrl = "/dashboard/admin";
      }
    }
  }
}