import { Injectable,Output,EventEmitter} from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;
  @Output() userDetails: any = new EventEmitter();

  public setItem(key:any, value:any) {
    value = JSON.stringify(value)
    this.ls.setItem(key, value)
    return true
  }

  public getItem(key:any) {
    let value = this.ls.getItem(key)
    try {
      return JSON.parse(value?value:"")
    } catch (e) {
      return null
    }
  }
  
  public clear() {
    this.ls.clear();
  }

  public setUser(value: any): void {
    this.userDetails.next(value);
  }
  public getUser(): Observable<any> {
    return this.userDetails;
  }

  public removeItem(key:any) {
    this.ls.removeItem(key);
  }
}