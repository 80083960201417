import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { getQueryParam } from "../helpers/url.helper";
import { ThemeService } from "./theme.service";
import { Direction } from "@angular/cdk/bidi";

export interface ILayoutConf {
  navigationPos?: string; // side, top
  sidebarStyle?: string; // full, compact, closed
  sidebarCompactToggle?: boolean; // sidebar expandable on hover
  sidebarColor?: string; // Sidebar background color http://demos.ui-lib.com/egret-doc/#egret-colors
  dir?: Direction; // ltr, rtl
  isMobile?: boolean; // updated automatically
  useBreadcrumb?: boolean; // Breadcrumb enabled/disabled
  breadcrumb?: string; // simple, title
  topbarFixed?: boolean; // Fixed header
  footerFixed?: boolean; // Fixed Footer
  topbarColor?: string; // Header background color http://demos.ui-lib.com/egret-doc/#egret-colors
  footerColor?: string // Header background color http://demos.ui-lib.com/egret-doc/#egret-colors
  matTheme?: string; // material theme. egret-blue, egret-navy, egret-dark-purple, egret-dark-pink
  perfectScrollbar?: boolean;
}

export interface AdminContainerClasses {
  "navigation-top": boolean,
  "sidebar-full": boolean,
  "sidebar-compact": boolean,
  "compact-toggle-active": boolean,
  "sidebar-compact-big": boolean,
  "sidebar-opened": boolean,
  "sidebar-closed": boolean,
  "fixed-topbar": boolean,
}

export interface ILayoutChangeOptions {
  duration?: number;
  transitionClass?: boolean;
}
interface IAdjustScreenOptions {
  browserEvent?: any;
  route?: string;
}

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  public layoutConf!: ILayoutConf;
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  public isMobile!: boolean;
  public currentRoute: string | undefined;
  public fullWidthRoutes = ["shop"];

  constructor(private themeService: ThemeService) {
    this.setAppLayout(
      //******** SET YOUR LAYOUT OPTIONS HERE *********
      /*{
        navigationPos: "side", // side, top
        sidebarStyle: "full", // full, compact, closed
        sidebarColor: "black", // http://demos.ui-lib.com/egret-doc/#egret-colors
        sidebarCompactToggle: true, // applied when "sidebarStyle" is "compact"
        dir: "ltr", // ltr, rtl
        useBreadcrumb: false,
        topbarFixed: false,
        footerFixed: false,
        topbarColor: "white", // http://demos.ui-lib.com/egret-doc/#egret-colors
        footerColor: "black", // http://demos.ui-lib.com/egret-doc/#egret-colors
        matTheme: "egret-navy", // ihm-theme, egret-blue, egret-navy, egret-light-purple, egret-dark-purple, egret-dark-pink
        breadcrumb: "simple", // simple, title
        perfectScrollbar: true
      }*/
      {
        "navigationPos": "side",
        "sidebarStyle": "full",
        "sidebarColor": "slate",
        "sidebarCompactToggle": false,
        "dir": "ltr",
        "useBreadcrumb": false,
        "topbarFixed": false,
        "footerFixed": false,
        "topbarColor": "white",
        "footerColor": "slate",
        "matTheme": "egret-navy",
        "breadcrumb": "simple",
        "perfectScrollbar": true,
        // "isMobile": false
      }
      
       /* {
          "navigationPos": "top",
          "sidebarStyle": "full",
          "sidebarColor": "white",
          "sidebarCompactToggle": false,
          "dir": "ltr",
          "useBreadcrumb": true,
          "topbarFixed": false,
          "footerFixed": false,
          "topbarColor": "white",
          "footerColor": "dark-blue",
          "matTheme": "egret-navy",
          "breadcrumb": "simple",
          "perfectScrollbar": true,
          "isMobile": false
        }*/
    ); 
  }

  setAppLayout(layoutConf: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConf };
    this.applyMatTheme(this.layoutConf.matTheme);

    //******* Only for demo purpose ***
    // this.setLayoutFromQuery();
    //**********************
  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    if (this.layoutConf.matTheme !== lc.matTheme && lc.matTheme) {
      this.themeService.changeTheme(this.layoutConf.matTheme, lc.matTheme);
    }

    this.layoutConf = Object.assign(this.layoutConf, lc);
    this.layoutConfSubject.next(this.layoutConf);
  }

  applyMatTheme(theme:any) {
    this.themeService.applyMatTheme(this.layoutConf.matTheme ?? 'defaultTheme');
  }

  setLayoutFromQuery() {
    let layoutConfString:any = getQueryParam("layout");
    let prevTheme = this.layoutConf.matTheme;
    try {
      this.layoutConf = JSON.parse(layoutConfString);
      this.themeService.changeTheme(prevTheme, this.layoutConf.matTheme ?? 'defaultTheme');
    } catch (e) {}
  }

  adjustLayout(options: IAdjustScreenOptions = {}) {
    let sidebarStyle: string;
    this.isMobile = this.isSm();
    this.currentRoute = options.route || this.currentRoute;
    sidebarStyle = "full";
    // sidebarStyle = this.isMobile ? "closed" : "full";

    if (this.currentRoute) {
      this.fullWidthRoutes.forEach(route => {
        if (this.currentRoute?.indexOf(route) !== -1) {
          sidebarStyle = "closed";
        }
      });
    }

    this.publishLayoutChange({
      isMobile: this.isMobile,
      sidebarStyle: sidebarStyle
    });
  }
  isSm() {
    return window.matchMedia(`(max-width: 959px)`).matches;
  }
    }
