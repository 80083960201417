import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { EventManager } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  
  public onResize$ = new EventEmitter<{
    width: number,
    height: number,
  }>();

  snackBarRef!: MatSnackBarRef<TextOnlySnackBar>;

  constructor(private eventManager: EventManager, private snackBar: MatSnackBar) {
    const element: HTMLElement = document.createElement('div');
    this.eventManager.addEventListener(element, 'resize', (event:any) => {
      this.onResize$.emit({
        width: event.target.innerWidth,
        height: event.target.innerHeight
      });
    });
  }

  getWindowSize(){
    this.onResize$.emit({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  showSnackBar(){
    this.snackBarRef = this.snackBar.open("Please make sure that the application is open in full screen.", "Dismiss");
  }

  dismissAndResetSnackBar(){
    this.snackBarRef.dismiss();
    this.snackBarRef ==undefined;
  }
}
