import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { MenuCode } from "../common/Enums";
import { LocalStoreService } from "./local-store.service";
import { log } from "handsontable/helpers";
import { UserRightsService } from "src/app/views/security/user-rights/user-rights.service";
import { SettingsService } from "src/app/views/settings/settings.service";

export interface IActionItem {
  isAdd: boolean;
  isDelete: boolean;
  isEdit: boolean;
  isRestore: boolean;
  isView: boolean;
}
export interface IMenuItem {
  id?: number;
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string | ""; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  parentId?: number;
  code?: string;
  action: IActionItem|null;
}

export interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  badges?: IBadge[];
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  isWarningNotification: boolean = false;
  isForceNotification: boolean = false;
  constructor(
    private cookieService: CookieService,
    private snack: MatSnackBar,
    private userRightsService: UserRightsService,
    private settingsService: SettingsService,
    private ls: LocalStoreService
  ) {
  }

  assignCountries(){
    this.settingsService.getAllCountriesByFliterKeyValue().subscribe((value:any)=>{
      this.ls.setItem('countries',JSON.stringify(value.rows))
    })
  }

  assignMenuItems() {
    // this.isWarningNotification = this.cookieService.check("isWarningNotification") ? JSON.parse(this.cookieService.get("isWarningNotification")) : false;
    // this.isForceNotification = this.cookieService.check("isForceNotification") ? JSON.parse(this.cookieService.get("isForceNotification")) : false;
    // let isAdmin = this.localStorageService.getItem("isAdmin") === true;
    // let localUserObj = this.localStorageService.getItem("user");
    // this.menuItems.next([]);
    let isAdmin = this.cookieService.check("isAdmin") ? JSON.parse(this.cookieService.get("isAdmin")) : false;
    //console.log('testmefeb',this.cookieService.get("userId"),this.cookieService.get("roleId"));
    //#region User Rights Wise Dynamic Menu Binding
    let userId = JSON.parse(this.cookieService.get("userId"));
    let roleId = JSON.parse(this.cookieService.get("roleId"));
    let userRole = this.cookieService.get("userRole") ? this.cookieService.get("userRole") : null;
    this.userRightsService
      .getMenuRightsByUserAndRole(userId, roleId)
      .then((res:any) => {
        let _allMenu = res.allMenus;
        let _menuList = [...res.userMenuRightRecords, ...res.userRoleMenuRightRecords];
        let showMenusIds = _menuList.map((x: any) => x.userMenuId);

        let selectedMenuIds = _allMenu.ToList().Select((x:any) => x.id);
        // _allMenu = (isAdmin || (userRole ? userRole.trim().toLocaleLowerCase() == 'admin' : null)) 
        // ? _allMenu.ToList().Where((x:any) => x.code !== MenuCode.OwnerDashboard).ToArray()
        // : _allMenu.ToList().Where((x:any) => x.code !== MenuCode.AdminDashboard).ToArray();

        let menu = _allMenu.ToList().Where((x:any) => (x.parentId == null || selectedMenuIds.Contains(x.id))).ToArray();
          
        // let menu = isAdmin ? _allMenu.ToList().Where((x) => x.code !== MenuCode.OwnerDashboard).ToArray()
        //   : _allMenu.ToList().Where((x) => x.code !== MenuCode.AdminDashboard && (x.parentId == null || selectedMenuIds.Contains(x.id))).ToArray();

        let menus = menu.map((value:any) => {
          return {
            id: value.id,
            name: value.title,
            value: value.id,
            parentId: value.parentId,
            state: value.route,
            iconName: value.iconName,
            isAction: value.isAction,
            code: value.code,
            action: _menuList.filter(x => x.userMenuId == value.id)
              .map(x => {
                let y = {
                  isAdd: x.isAdd,
                  isDelete: x.isDelete,
                  isEdit: x.isEdit,
                  isRestore: x.isRestore,
                  isView: x.isView
                }
                return y;
              })[0]
          };
        });
        const nested = (items:any, value = null, link = "parentId") =>
          items
            .filter((item:any) => item[link] === value)
            .map((item:any) => ({
              ...item,
              sub: nested(items, item.value),
            }));
        let userMenuData = nested(menus);
        let menuItemList: IMenuItem[] = [];
        /*userMenuData.map((x:any) => {
          if (x.sub.length > 0) {
            let item = {
              id: x.id,
              name: x.name,
              value: x.value,
              parentId: x.parentId,
              type: "dropDown",
              tooltip: x.name,
              icon: x.iconName,
              state: x.state,
              sub: x.sub,
              isAction: x.isAction,
              code: x.code,
              action: x.action
            };
            menuItemList.push(item);
          } else {
            if (x.parentId == null && x.isAction) {
              let item = {
                id: x.id,
                name: x.name,
                value: x.value,
                parentId: x.parentId,
                type: "link",
                tooltip: x.name,
                icon: x.iconName,
                state: x.state,
                sub: x.sub,
                isAction: x.isAction,
                code: x.code,
                action: x.action
              };
              menuItemList.push(item);
            }
          }

        });*/


        const menuSetFunction = (userMenuData:any)=>{
          let menuSub:any = []
          let menus:any = []
          userMenuData.map((x:any) => {
            if (x.sub.length > 0) {
              menuSub = menuSetFunction(x.sub)
              let item = {
                id: x.id,
                name: x.name,
                value: x.value,
                parentId: x.parentId,
                type: "dropDown",
                tooltip: x.name,
                icon: x.iconName,
                state: x.state,
                sub: x.sub,
                isAction: x.isAction,
                code: x.code,
                action: x.action
              };
              item.sub = menuSub
              menus.push(item)
            } else {
              if (x.isAction) {
                let item = {
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  parentId: x.parentId,
                  type: "link",
                  tooltip: x.name,
                  icon: x.iconName,
                  state: x.state,
                  sub: x.sub,
                  isAction: x.isAction,
                  code: x.code,
                  action: x.action
                };
                menus.push(item);
              }
            }
  
          });
          return menus
        }

        let menuItemListAll = menuSetFunction(userMenuData)
        menuItemList = menuItemListAll.filter((item: any) => showMenusIds.includes(item.id));
        this.menuItems.next(menuItemList);
        this.ls.removeItem('menuItemList');
        this.ls.setItem('menuItemList', JSON.stringify(menuItemList));
      })
      .catch((_httpErrorResponse: HttpErrorResponse) => {
        let errorMessage = _httpErrorResponse.error?.errors[0]?.message;
        this.snack.open(
          `${errorMessage || "Something went wrong!"}`,
          "OK",
          {
            duration: 3000,
          }
        );
      });





    








    // this.userRightsService
    //   .getAllMenus()
    //   .then((_allMenu) => {
    //     this.userRightsService
    //       .getMenuRightsByUserId(userId)
    //       .then((_menuList) => {
    //         let selectedMenuIds = _menuList
    //           .ToList()
    //           .Select((x) => x.userMenuId);
    //         let menu = isAdmin ? _allMenu : _allMenu
    //           .ToList()
    //           .Where(
    //             (x) => x.parentId == null || selectedMenuIds.Contains(x.id)
    //           )
    //           .ToArray();

    //           // if(shipOwnerId && shipOwnerId!=0){
    //           //   let defaultMenu =  _allMenu.filter(x=>x.id == shipOwnerId)
    //           //   menu = [menu, ...defaultMenu]
    //           // }
    //           // if(shipManagerId && shipManagerId!=0){
    //           //   let defaultMenu =  _allMenu.filter(x=>x.id == shipManagerId)
    //           //   menu = [menu, ...defaultMenu]
    //           // }

    //         let menus = menu.map((value) => {
    //           return {
    //             name: value.title,
    //             value: value.id,
    //             parentId: value.parentId,
    //             state: value.route,
    //             iconName: value.iconName,
    //           };
    //         });
    //         const nested = (items, value = null, link = "parentId") =>
    //           items
    //             .filter((item) => item[link] === value)
    //             .map((item) => ({
    //               ...item,
    //               sub: nested(items, item.value),
    //             }));
    //         let userMenuData = nested(menus);
    //         let menuItemList: IMenuItem[] = [];
    //         let itemsList = userMenuData.map((x) => {
    //           if (x.sub.length > 0) {
    //             let item = {
    //               name: x.name,
    //               value: x.value,
    //               parentId: x.parentId,
    //               type: "dropDown",
    //               tooltip: x.name,
    //               icon: x.iconName,
    //               state: x.state,
    //               sub: x.sub,
    //             };
    //             menuItemList.push(item);
    //           } else {
    //             if (x.parentId == null && x.isAction) {
    //               let item = {
    //                 name: x.name,
    //                 value: x.value,
    //                 parentId: x.parentId,
    //                 type: "link",
    //                 tooltip: x.name,
    //                 icon: x.iconName,
    //                 state: x.state,
    //                 sub: x.sub,
    //               };
    //               menuItemList.push(item);
    //             }
    //           }

    //         });
    //         //console.log('menuItemList', menuItemList);
    //         this.menuItems.next(menuItemList);
    //       })
    //       .catch((_httpErrorResponse: HttpErrorResponse) => {
    //         let errorMessage = _httpErrorResponse.error?.errors[0]?.message;
    //         this.snack.open(
    //           `${errorMessage || "Something went wrong!"}`,
    //           "OK",
    //           {
    //             duration: 3000,
    //           }
    //         );
    //       });
    //   })
    //   .catch((_httpErrorResponse: HttpErrorResponse) => {
    //     let errorMessage = _httpErrorResponse.error?.errors[0]?.message;
    //     this.snack.open(`${errorMessage || "Something went wrong!"}`, "OK", {
    //       duration: 3000,
    //     });
    //   });
    //#endregion

    //#region Statuc Menu Binding
    // User => Admin
    // if (isAdmin) {
    //   this.menuItems.next(this.iconMenu);
    // } else {
    //   // Check if owner or manager
    //   let isOwner = this.cookieService.check("shipOwnerId") ? JSON.parse(this.cookieService.get("shipOwnerId")) : false;
    //   if (isOwner) {
    //     this.menuItems.next(this.ShipOwnerMenu);
    //   } else {
    //     let isManager = this.cookieService.check("shipManagerId") ? JSON.parse(this.cookieService.get("shipManagerId")) : false;
    //     if (isManager) {
    //       this.menuItems.next(this.ShipManagerMenu);
    //     } else {
    //       this.menuItems.next([]);
    //       //console.log("None of the cases matched! Something went wrong.");
    //     }
    //   }
    // }
    //#endregion

  }

  /** System Admin Menu */
  iconMenu: IMenuItem[] = [
    //#region THEME MENU
    // {
    //   name: "HOME",
    //   type: "icon",
    //   tooltip: "Home",
    //   icon: "home",
    //   state: "home"
    // },
    // {
    //   name: "PROFILE",
    //   type: "icon",
    //   tooltip: "Profile",
    //   icon: "person",
    //   state: "profile/overview"
    // },
    // {
    //   name: "TOUR",
    //   type: "icon",
    //   tooltip: "Tour",
    //   icon: "flight_takeoff",
    //   state: "tour"
    // },
    // {
    //   type: "separator",
    //   name: "Main Items"
    // },
    // {
    //   name: "DASHBOARD",
    //   type: "dropDown",
    //   tooltip: "Dashboard",
    //   icon: "dashboard",
    //   state: "dashboard",
    //   sub: [
    //     { name: "Default", state: "default" },
    //     { name: "Analytics", state: "analytics" },
    //     { name: "Cryptocurrency", state: "crypto" },
    //     { name: "Dark Cards", state: "dark" }
    //   ]
    // },
    //#endregion
    {
      name: "Admin Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard/admin",
      action: null
      //state: "dashboard",
    },
    {
      name: "Emissions",
      type: "dropDown",
      tooltip: "Emissions",
      icon: "directions_boat",
      action: null,
      //state: "dashboard",
      sub: [
        { name: "T-Of Quantity Consumed", state: "environmental/emissions/tonnesofquantityconsumed" },
        { name: "T-Of CO2e", state: "environmental/emissions/tonnesofquantityconsumed" }
      ],
    },
    {
      name: "Administration",
      type: "dropDown",
      tooltip: "Administration",
      icon: "supervised_user_circle",
      //state: "dashboard",
      sub: [
        { name: "Upload Purchase Orders", state: "poAutomation" },
        { name: "Pending Audits", state: "audit/pending" },
        { name: "Pending Review", state: "tobeAudited" },
        { name: "MD SDoC Audit Pending", state: "auditPending" }
      ],
      action: null
    },
    {
      name: "Security",
      type: "dropDown",
      tooltip: "Security",
      icon: "lock",
      //state: "dashboard",
      sub: [
        { name: "Users", state: "user/users" },
        {
          name: "User Profile", state: "user/user-profile",
          badges: [{ color: this.isWarningNotification ? 'accent' : this.isForceNotification ? 'warn' : '', value: "New" }]
        },
        { name: "User Rights", state: "security/user-rights" },
        { name: "User Role Rights", state: "security/user-role-rights" },
        { name: "User Menu", state: "security/user-menu" },
      ],
      action: null
    },
    {
      name: "Menu",
      type: "dropDown",
      tooltip: "Dashboard",
      icon: "format_list_bulleted",
      //state: "dashboard",
      sub: [
        { name: "Registered Owner", state: "master/registered-ship-owner" },
        { name: "Ship Owner", state: "master/ship-owner" },
        { name: "Ship Manager", state: "master/ship-manager" },
        { name: "Supplier", state: "supplier/supplier" },
        { name: "Equipment", state: "master/equipment" },
        { name: "Suspected Keyword", state: "master/keyword" },
        { name: "User Category", state: "master/user-category" }
      ],
      action: null
    },

    //#region Temporary Closed Menu
    // {
    //   name: "Master",
    //   type: "dropDown",
    //   tooltip: "Master",
    //   icon: "format_list_bulleted",
    //   state: "master",
    //   sub: [
    //     { name: "Fleet", state: "fleet" },
    //     { name: "Sub Fleet", state: "sub-fleet" },
    //     //{ name: "Ship", state: "ship" },
    //     //{ name: "Ship Owner", state: "ship-owner" },
    //     //{ name: "Ship Manager", state: "ship-manager" },
    //     // { name: "Ship Yard", state: "ship-yard" },
    //     //{ name: "Supplier", state: "supplier" },
    //     { name: "Equipment", state: "equipment" },
    //     { name: "Users", state: "users" },
    //     //{ name: "Ship PO", state: "ship-po" },
    //   ],
    // },
    // {
    //   name: "Ship Project",
    //   type: "link",
    //   tooltip: "Ship Project",
    //   icon: "format_list_bulleted",
    //   state: "ship-project/preparation",
    // },
    // {
    //   name: "Activities",
    //   type: "dropDown",
    //   tooltip: "Activities",
    //   icon: "local_activity",
    //   state: "dashboard",
    //   sub: [
    //     { name: "Upload IHM Document", state: "sessions/404" },
    //     { name: "Upload PO", state: "sessions/404" },
    //     { name: "Upload MDs", state: "sessions/404" },
    //     { name: "Upload Sdocs", state: "sessions/404" },
    //   ],
    // },
    // {
    //   name: "Security",
    //   type: "dropDown",
    //   tooltip: "Security",
    //   icon: "security",
    //   state: "security",
    //   sub: [
    //     { name: "User Menu", state: "session/404" },
    //     { name: "User Rights", state: "user-rights" },
    //     { name: "Role Rights", state: "sessions/404" },
    //   ],
    // },
    // {
    //   name: "Administrative Settings",
    //   type: "dropDown",
    //   tooltip: "Administrative Settings",
    //   icon: "settings",
    //   state: "dashboard",
    //   sub: [
    //     {
    //       name: "Tools",
    //       type: "dropDown",
    //       sub: [{ name: "Tenant Creation", state: "sessions/404" }],
    //     },
    //     {
    //       name: "Security",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Role Rights", state: "sessions/404" },
    //         { name: "User Rights", state: "sessions/404" },
    //       ],
    //     },
    //     {
    //       name: "Master List",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Users", state: "sessions/404" },
    //         { name: "Role", state: "sessions/404" },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Reports",
    //   type: "dropDown",
    //   tooltip: "Reports",
    //   icon: "library_books",
    //   state: "dashboard",
    //   sub: [
    //     { name: "MDs Pending", state: "sessions/404" },
    //     { name: "SDOCs Pending", state: "sessions/404" },
    //   ],
    // },
    //#endregion

    //#region THEME MENU
    // {
    //   name: "CRUD Table",
    //   type: "link",
    //   tooltip: "CRUD Table",
    //   icon: "format_list_bulleted",
    //   state: "cruds/ngx-table"
    // },
    // {
    //   name: "ECOMMERCE",
    //   type: "dropDown",
    //   tooltip: "Shop",
    //   icon: "shopping_cart",
    //   state: "shop",
    //   sub: [
    //     { name: "PRODUCTS", state: "" },
    //     { name: "PRODUCT DETAILS", state: "products/5a9ae2106f155194e5c95d67" },
    //     { name: "CART", state: "cart" },
    //     { name: "CHECKOUT", state: "checkout" }
    //   ]
    // },
    // {
    //   name: "INBOX",
    //   type: "link",
    //   tooltip: "Inbox",
    //   icon: "inbox",
    //   state: "inbox",
    //   badges: [{ color: "primary", value: "4" }]
    // },
    // {
    //   name: "Invoice",
    //   type: "link",
    //   icon: "receipt",
    //   state: "invoice/list"
    // },
    // {
    //   name: "CHAT",
    //   type: "link",
    //   tooltip: "Chat",
    //   icon: "chat",
    //   state: "chat",
    //   badges: [{ color: "warn", value: "1" }]
    // },
    // {
    //   name: "CALENDAR",
    //   type: "link",
    //   tooltip: "Calendar",
    //   icon: "date_range",
    //   state: "calendar"
    // },
    // {
    //   name: "Todo",
    //   type: "link",
    //   tooltip: "Todo",
    //   icon: "center_focus_strong",
    //   state: "todo/list"
    // },
    // {
    //   name: "DIALOGS",
    //   type: "dropDown",
    //   tooltip: "Dialogs",
    //   icon: "filter_none",
    //   state: "dialogs",
    //   sub: [
    //     { name: "CONFIRM", state: "confirm" },
    //     { name: "LOADER", state: "loader" }
    //   ]
    // },
    // {
    //   name: "Material Kits",
    //   type: "dropDown",
    //   tooltip: "Material",
    //   icon: "favorite",
    //   state: "material",
    //   badges: [{ color: "primary", value: "60+" }],
    //   sub: [
    //     {
    //       name: "Form controls",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Autocomplete", state: "autocomplete" },
    //         { name: "Checkbox", state: "checkbox" },
    //         { name: "Datepicker", state: "datepicker" },
    //         { name: "Form Field", state: "form-field" },
    //         { name: "Input Field", state: "input-field" },
    //         { name: "Radio Button", state: "radio-button" },
    //         { name: "Select", state: "select" },
    //         { name: "Slider", state: "slider" },
    //         { name: "Slider Toggle", state: "slider-toggle" }
    //       ]
    //     },
    //     {
    //       name: "Navigation",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Menu", state: "menu" },
    //         { name: "Sidenav", state: "sidenav" },
    //         { name: "Toolbar", state: "toolbar" }
    //       ]
    //     },
    //     {
    //       name: "Layout",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Card", state: "card" },
    //         { name: "Divider", state: "divider" },
    //         { name: "Expansion Panel", state: "expansion-panel" },
    //         { name: "Grid", state: "grid" },
    //         { name: "List", state: "list" },
    //         { name: "Stepper", state: "stepper" },
    //         { name: "Tab", state: "tab-group" },
    //         { name: "Tree", state: "tree" }
    //       ]
    //     },
    //     {
    //       name: "Buttons & Indicators",
    //       type: "dropDown",
    //       sub: [
    //         { name: "BUTTONS", state: "buttons" },
    //         { name: "Button Toggle", state: "button-toggle" },
    //         { name: "Badge", state: "badge" },
    //         { name: "Chips", state: "chips" },
    //         { name: "Icons", state: "icons" },
    //         { name: "Progress Spinner", state: "progress-spinner" },
    //         { name: "Progress Bar", state: "progress-bar" },
    //         { name: "Ripples", state: "ripples" }
    //       ]
    //     },
    //     {
    //       name: "Popups & Modals",
    //       type: "dropDown",
    //       sub: [
    //         { name: "Tooltip", state: "tooltip" },
    //         { name: "Bottom Sheet", state: "bottom-sheet" },
    //         { name: "Dialog", state: "dialog" },
    //         { name: "Snackbar", state: "snackbar" }
    //       ]
    //     },
    //     {
    //       name: "Data Table",
    //       type: "dropDown",
    //       sub: [
    //         { name: "paginator", state: "paginator" },
    //         { name: "Sort Header", state: "sort-header" },
    //         { name: "Table", state: "table" }
    //       ]
    //     },
    //     { name: "Buttons Loading", state: "loading-buttons" }
    //   ]
    // },
    // {
    //   name: "FORMS",
    //   type: "dropDown",
    //   tooltip: "Forms",
    //   icon: "description",
    //   state: "forms",
    //   sub: [
    //     { name: "BASIC", state: "basic" },
    //     { name: "EDITOR", state: "editor" },
    //     { name: "UPLOAD", state: "upload" },
    //     { name: "WIZARD", state: "wizard" }
    //   ]
    // },
    // {
    //   name: "TABLES",
    //   type: "dropDown",
    //   tooltip: "Tables",
    //   icon: "format_line_spacing",
    //   state: "tables",
    //   sub: [
    //     { name: "FULLSCREEN", state: "fullscreen" },
    //     { name: "PAGING", state: "paging" },
    //     { name: "FILTER", state: "filter" },
    //     { name: "Material Table", state: "mat-table" }
    //   ]
    // },
    // {
    //   name: "PROFILE",
    //   type: "dropDown",
    //   tooltip: "Profile",
    //   icon: "person",
    //   state: "profile",
    //   badges: [{ color: "primary", value: "2" }],
    //   sub: [
    //     { name: "OVERVIEW", state: "overview" },
    //     { name: "SETTINGS", state: "settings" },
    //     { name: "BLANK", state: "blank" }
    //   ]
    // },
    // {
    //   name: "TOUR",
    //   type: "link",
    //   tooltip: "Tour",
    //   icon: "flight_takeoff",
    //   state: "tour"
    // },
    // {
    //   name: "MAP",
    //   type: "link",
    //   tooltip: "Map",
    //   icon: "add_location",
    //   state: "map"
    // },
    // {
    //   name: "CHARTS",
    //   type: "dropDown",
    //   tooltip: "Charts",
    //   icon: "show_chart",
    //   sub: [
    //     {
    //       name: "eChart",
    //       type: "dropDown",
    //       state: "chart",
    //       sub: [
    //         { name: "Pie", state: "pie" },
    //         { name: "Bar", state: "bar" },
    //         { name: "Radar", state: "radar" },
    //         { name: "Heatmap", state: "heatmap" },
    //       ]
    //     },
    //     { name: "Chart js", state: "charts" }
    //   ]
    // },
    // // {
    // //   name: "CHARTS",
    // //   type: "link",
    // //   tooltip: "Charts",
    // //   icon: "show_chart",
    // //   state: "charts"
    // // },
    // {
    //   name: "DND",
    //   type: "link",
    //   tooltip: "Drag and Drop",
    //   icon: "adjust",
    //   state: "dragndrop"
    // },
    // {
    //   name: "Page Layouts",
    //   type: "dropDown",
    //   icon: "view_carousel",
    //   state: "page-layouts",
    //   sub: [
    //     { name: "Left sidebar card", state: "left-sidebar-card" },
    //     { name: "Right sidebar card", state: "right-sidebar-card" },
    //     { name: "Full width card", state: "full-width-card" },
    //     { name: "Full width card tab", state: "full-width-card-tab" },

    //     { name: "Full width plain", state: "full-width-plain" },
    //     { name: "Left sidebar plain", state: "left-sidebar-plain" }
    //   ]
    // },
    // {
    //   name: "SESSIONS",
    //   type: "dropDown",
    //   tooltip: "Pages",
    //   icon: "view_carousel",
    //   state: "sessions",
    //   sub: [
    //     { name: "SIGNUP", state: "signup" },
    //     { name: "Signup 2", state: "signup2" },
    //     { name: "Signup 3", state: "signup3" },
    //     { name: "Signup 4", state: "signup4" },
    //     { name: "SIGNIN", state: "signin" },
    //     { name: "Signin 2", state: "signin2" },
    //     { name: "Signin 3", state: "signin3" },
    //     { name: "Signin 4", state: "signin4" },
    //     { name: "FORGOT", state: "forgot-password" },
    //     { name: "LOCKSCREEN", state: "lockscreen" },
    //     { name: "NOTFOUND", state: "404" },
    //     { name: "ERROR", state: "error" }
    //   ]
    // },
    // {
    //   name: "Utilities",
    //   type: "dropDown",
    //   icon: "format_list_bulleted",
    //   state: "utilities",
    //   sub: [
    //     { name: "Border", state: "border" },
    //     { name: "Color", state: "color" },
    //     { name: "Spacing", state: "spacing" },
    //     { name: "Typography", state: "typography" }
    //   ]
    // },
    // {
    //   name: "OTHERS",
    //   type: "dropDown",
    //   tooltip: "Others",
    //   icon: "blur_on",
    //   state: "others",
    //   sub: [
    //     { name: "GALLERY", state: "gallery" },
    //     { name: "PRICINGS", state: "pricing" },
    //     { name: "USERS", state: "users" },
    //     { name: "BLANK", state: "blank" }
    //   ]
    // },
    // {
    //   name: "MATICONS",
    //   type: "link",
    //   tooltip: "Material Icons",
    //   icon: "store",
    //   state: "icons"
    // },
    // {
    //   name: "Multi Level",
    //   type: "dropDown",
    //   tooltip: "Multi Level",
    //   icon: "format_align_center",
    //   state: "",
    //   sub: [
    //     {
    //       name: "Level Two",
    //       type: "dropDown",
    //       state: "fake-1",
    //       sub: [
    //         { name: "Level Three", state: "fake-2" },
    //         { name: "Level Three", state: "fake-3" }
    //       ]
    //     },
    //     { name: "Level Two", state: "fake-4" },
    //     { name: "Level Two", state: "fake-5" }
    //   ]
    // },
    // {
    //   name: "DOC",
    //   type: "extLink",
    //   tooltip: "Documentation",
    //   icon: "library_books",
    //   state: "https://demos.ui-lib.com/egret-doc/"
    // }
    //#endregion

  ];

  separatorMenu: IMenuItem[] = [
    {
      type: "separator",
      name: "Custom components",
      action: null
    },
    {
      name: "Menu",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
      action: null
    },
    {
      name: "INBOX",
      type: "link",
      tooltip: "Inbox",
      icon: "inbox",
      state: "inbox",
      action: null
    },
    {
      name: "CHAT",
      type: "link",
      tooltip: "Chat",
      icon: "chat",
      state: "chat",
      action: null
    },
    {
      name: "CRUD Table",
      type: "link",
      tooltip: "CRUD Table",
      icon: "format_list_bulleted",
      state: "cruds/ngx-table",
      action: null
    },
    {
      name: "DIALOGS",
      type: "dropDown",
      tooltip: "Dialogs",
      icon: "filter_none",
      state: "dialogs",
      sub: [
        { name: "CONFIRM", state: "confirm" },
        { name: "LOADER", state: "loader" },
      ],
      action: null
    },
    {
      name: "PROFILE",
      type: "dropDown",
      tooltip: "Profile",
      icon: "person",
      state: "profile",
      sub: [
        { name: "OVERVIEW", state: "overview" },
        { name: "SETTINGS", state: "settings" },
        { name: "BLANK", state: "blank" },
      ],
      action: null
    },
    {
      name: "TOUR",
      type: "link",
      tooltip: "Tour",
      icon: "flight_takeoff",
      state: "tour",
      action: null
    },
    {
      type: "separator",
      name: "Integrated components",
      action: null
    },
    {
      name: "CALENDAR",
      type: "link",
      tooltip: "Calendar",
      icon: "date_range",
      state: "calendar",
      action: null
    },
    {
      name: "MATERIAL",
      type: "dropDown",
      tooltip: "Material",
      icon: "favorite",
      state: "material",
      sub: [
        { name: "BUTTONS", state: "buttons" },
        { name: "Button Toggle", state: "button-toggle" },
        { name: "Buttons Loading", state: "loading-buttons" },
        { name: "CARDS", state: "cards" },
        { name: "GRIDS", state: "grids" },
        { name: "LISTS", state: "lists" },
        { name: "MENU", state: "menu" },
        { name: "TABS", state: "tabs" },
        { name: "SELECT", state: "select" },
        { name: "RADIO", state: "radio" },
        { name: "AUTOCOMPLETE", state: "autocomplete" },
        { name: "SLIDER", state: "slider" },
        { name: "PROGRESS", state: "progress" },
        { name: "SNACKBAR", state: "snackbar" },
      ],
      action: null
    },
    {
      name: "FORMS",
      type: "dropDown",
      tooltip: "Forms",
      icon: "description",
      state: "forms",
      sub: [
        { name: "BASIC", state: "basic" },
        { name: "EDITOR", state: "editor" },
        { name: "UPLOAD", state: "upload" },
        { name: "WIZARD", state: "wizard" },
      ],
      action: null
    },
    {
      name: "TABLES",
      type: "dropDown",
      tooltip: "Tables",
      icon: "format_line_spacing",
      state: "tables",
      sub: [
        { name: "FULLSCREEN", state: "fullscreen" },
        { name: "PAGING", state: "paging" },
        { name: "FILTER", state: "filter" },
      ],
      action: null
    },
    {
      name: "MAP",
      type: "link",
      tooltip: "Map",
      icon: "add_location",
      state: "map",
      action: null
    },
    {
      name: "CHARTS",
      type: "link",
      tooltip: "Charts",
      icon: "show_chart",
      state: "charts",
      action: null
    },
    {
      name: "DND",
      type: "link",
      tooltip: "Drag and Drop",
      icon: "adjust",
      state: "dragndrop",
      action: null
    },
    {
      type: "separator",
      name: "Other components",
      action: null
    },
    {
      name: "SESSIONS",
      type: "dropDown",
      tooltip: "Pages",
      icon: "view_carousel",
      state: "sessions",
      sub: [
        { name: "SIGNUP", state: "signup" },
        { name: "SIGNIN", state: "signin" },
        { name: "FORGOT", state: "forgot-password" },
        { name: "LOCKSCREEN", state: "lockscreen" },
        { name: "NOTFOUND", state: "404" },
        { name: "ERROR", state: "error" },
      ],
      action: null
    },
    {
      name: "OTHERS",
      type: "dropDown",
      tooltip: "Others",
      icon: "blur_on",
      state: "others",
      sub: [
        { name: "GALLERY", state: "gallery" },
        { name: "PRICINGS", state: "pricing" },
        { name: "USERS", state: "users" },
        { name: "BLANK", state: "blank" },
      ],
      action: null
    },
    {
      name: "MATICONS",
      type: "link",
      tooltip: "Material Icons",
      icon: "store",
      state: "icons",
      action: null
    },
    {
      name: "DOC",
      type: "extLink",
      tooltip: "Documentation",
      icon: "library_books",
      state: "https://demos.ui-lib.com/egret-doc/",
      action: null
    },
  ];

  plainMenu: IMenuItem[] = [
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
      action: null
    },
    {
      name: "INBOX",
      type: "link",
      tooltip: "Inbox",
      icon: "inbox",
      state: "inbox",
      action: null
    },
    {
      name: "CHAT",
      type: "link",
      tooltip: "Chat",
      icon: "chat",
      state: "chat",
      action: null
    },
    {
      name: "CRUD Table",
      type: "link",
      tooltip: "CRUD Table",
      icon: "format_list_bulleted",
      state: "cruds/ngx-table",
      action: null
    },
    {
      name: "CALENDAR",
      type: "link",
      tooltip: "Calendar",
      icon: "date_range",
      state: "calendar",
      action: null
    },
    {
      name: "DIALOGS",
      type: "dropDown",
      tooltip: "Dialogs",
      icon: "filter_none",
      state: "dialogs",
      sub: [
        { name: "CONFIRM", state: "confirm" },
        { name: "LOADER", state: "loader" },
      ],
      action: null
    },
    {
      name: "MATERIAL",
      type: "dropDown",
      icon: "favorite",
      state: "component",
      sub: [
        { name: "BUTTONS", state: "buttons" },
        { name: "Button Toggle", state: "button-toggle" },
        { name: "Buttons Loading", state: "loading-buttons" },
        { name: "CARDS", state: "cards" },
        { name: "GRIDS", state: "grids" },
        { name: "LISTS", state: "lists" },
        { name: "MENU", state: "menu" },
        { name: "TABS", state: "tabs" },
        { name: "SELECT", state: "select" },
        { name: "RADIO", state: "radio" },
        { name: "AUTOCOMPLETE", state: "autocomplete" },
        { name: "SLIDER", state: "slider" },
        { name: "PROGRESS", state: "progress" },
        { name: "SNACKBAR", state: "snackbar" },
      ],
      action: null
    },
    {
      name: "FORMS",
      type: "dropDown",
      tooltip: "Forms",
      icon: "description",
      state: "forms",
      sub: [
        { name: "BASIC", state: "basic" },
        { name: "EDITOR", state: "editor" },
        { name: "UPLOAD", state: "upload" },
        { name: "WIZARD", state: "wizard" },
      ],
      action: null
    },
    {
      name: "TABLES",
      type: "dropDown",
      tooltip: "Tables",
      icon: "format_line_spacing",
      state: "tables",
      sub: [
        { name: "FULLSCREEN", state: "fullscreen" },
        { name: "PAGING", state: "paging" },
        { name: "FILTER", state: "filter" },
      ],
      action: null
    },
    {
      name: "PROFILE",
      type: "dropDown",
      tooltip: "Profile",
      icon: "person",
      state: "profile",
      sub: [
        { name: "OVERVIEW", state: "overview" },
        { name: "SETTINGS", state: "settings" },
        { name: "BLANK", state: "blank" },
      ],
      action: null
    },
    {
      name: "TOUR",
      type: "link",
      tooltip: "Tour",
      icon: "flight_takeoff",
      state: "tour",
      action: null
    },
    {
      name: "MAP",
      type: "link",
      tooltip: "Map",
      icon: "add_location",
      state: "map",
      action: null
    },
    {
      name: "CHARTS",
      type: "link",
      tooltip: "Charts",
      icon: "show_chart",
      state: "charts",
      action: null
    },
    {
      name: "DND",
      type: "link",
      tooltip: "Drag and Drop",
      icon: "adjust",
      state: "dragndrop",
      action: null
    },
    {
      name: "SESSIONS",
      type: "dropDown",
      tooltip: "Pages",
      icon: "view_carousel",
      state: "sessions",
      sub: [
        { name: "SIGNUP", state: "signup" },
        { name: "SIGNIN", state: "signin" },
        { name: "FORGOT", state: "forgot-password" },
        { name: "LOCKSCREEN", state: "lockscreen" },
        { name: "NOTFOUND", state: "404" },
        { name: "ERROR", state: "error" },
      ],
      action: null
    },
    {
      name: "OTHERS",
      type: "dropDown",
      tooltip: "Others",
      icon: "blur_on",
      state: "others",
      sub: [
        { name: "GALLERY", state: "gallery" },
        { name: "PRICINGS", state: "pricing" },
        { name: "USERS", state: "users" },
        { name: "BLANK", state: "blank" },
      ],
      action: null
    },
    {
      name: "MATICONS",
      type: "link",
      tooltip: "Material Icons",
      icon: "store",
      state: "icons",
      action: null
    },
    {
      name: "DOC",
      type: "extLink",
      tooltip: "Documentation",
      icon: "library_books",
      state: "https://demos.ui-lib.com/egret-doc/",
      action: null
    },
  ];

  /** Ship Manager Menu */
  ShipManagerMenu: IMenuItem[] = [
    {
      name: "Owner Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard/owner",
      action: null
      //state: "dashboard",
    },
    {
      name: "Emissions",
      type: "dropDown",
      tooltip: "Emissions",
      icon: "directions_boat",
      //state: "dashboard",
      sub: [
        { name: "T-Of Quantity Consumed", state: "environmental/emissions/tonnesofquantityconsumed" }
      ],
      action: null
    },
    {
      name: "Security",
      type: "dropDown",
      tooltip: "Security",
      icon: "lock",
      //state: "dashboard",
      sub: [
        {
          name: "User Profile", state: "user/user-profile",
          badges: [{ color: this.isWarningNotification ? 'accent' : this.isForceNotification ? 'warn' : '', value: "New" }]
        },
      ],
      action: null
    },
    {
      name: "Menu",
      type: "dropDown",
      tooltip: "Dashboard",
      icon: "format_list_bulleted",
      //state: "dashboard",
      sub: [
        { name: "Ship Owner", state: "master/ship-owner" },
        { name: "Supplier", state: "supplier/supplier" },
      ],
      action: null
    },
  ];

  /** Ship Owner Menu */
  ShipOwnerMenu: IMenuItem[] = [
    {
      name: "Owner Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard/owner",
      action: null
      //state: "dashboard",
    },
    {
      name: "Emissions",
      type: "dropDown",
      tooltip: "Emissions",
      icon: "directions_boat",
      //state: "dashboard",
      sub: [
        { name: "Ship", state: "ship" }
      ],
      action: null
    },
    {
      name: "Security",
      type: "dropDown",
      tooltip: "Security",
      icon: "lock",
      //state: "dashboard",
      sub: [
        {
          name: "User Profile", state: "user/user-profile",
          badges: [{ color: this.isWarningNotification ? 'accent' : this.isForceNotification ? 'warn' : '', value: "New" }]
        },
      ],
      action: null
    },
    {
      name: "Menu",
      type: "dropDown",
      tooltip: "Dashboard",
      icon: "format_list_bulleted",
      //state: "dashboard",
      sub: [
        { name: "Ship Manager", state: "master/ship-manager" },
        { name: "Supplier", state: "supplier/supplier" },
      ],
      action: null
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>([]);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // !Settings menu data
  settingsMenuData = new BehaviorSubject<any>(null);
  settingsMenuData$ = this.settingsMenuData.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
