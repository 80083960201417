import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { EgretSideNavToggleDirective } from './egret-side-nav-toggle.directive';
import { EgretSidenavHelperDirective, EgretSidenavTogglerDirective } from './egret-sidenav-helper/egret-sidenav-helper.directive';
import { EgretHighlightDirective } from './egret-highlight.directive';
import { WhiteSpaceValidateDirective } from './white-space-validate.directive';
import { AtoselectStickPositionDirective } from './atoselect-stick-position.directive';
import { SharedMaterialModule } from '../shared-material.module';
import { NumberValidateDirective } from './number-validate.directive';


const directives = [
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  EgretSidenavHelperDirective,
  EgretSidenavTogglerDirective,
  EgretHighlightDirective,
  WhiteSpaceValidateDirective,
  AtoselectStickPositionDirective,
  NumberValidateDirective
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {}