import { Component } from '@angular/core';

@Component({
  selector: 'app-supplier-performance',
  templateUrl: './supplier-performance.component.html',
  styleUrls: ['./supplier-performance.component.css']
})
export class SupplierPerformanceComponent {

  colorScheme = 'vivid';  // Use a predefined string or custom color scheme

  supplierPerformanceData = [
    { "name": "ADAMAR INTERNATIONAL MARITIME SERVICES INC", "value": 10 },
    { "name": "Arthur Friedrichs Industriebedarf GmbH", "value": 15 },
    { "name": "Breckle Matratzenwerk Weida GmbH", "value": 8 },
    { "name": "Changchun Shipping PTE Ltd", "value": 10 },
    { "name": "CIS Elektrotechnik GmbH", "value": 12 },
    { "name": "Desin Marine & Industry Services Ltd.", "value": 10 },
    { "name": "Drew Marine", "value": 5 },
    { "name": "ELECTRICA SA Cable Electrical Material Supplier", "value": 12 },
    { "name": "Fast Marine Services Denizcilik Ltd", "value": 10 },
    { "name": "Fuji Trading Co., Ltd", "value": 15 },
    { "name": "Hidramar Group", "value": 10 },
    { "name": "KET Marine International B.V.", "value": 10 },
    { "name": "Kloska GmbH, Uwe", "value": 15 },
    { "name": "Ligabue S.p.A.", "value": 15 },
    { "name": "LUTZ Elevators", "value": 5 },
    { "name": "MEC Safety Systems GmbH", "value": 5 },
    { "name": "N.Y. Co. Ltd", "value": 8 },
    { "name": "NANJING CHUTUO SHIPBUILDING EQUIPMENT CO. LTD", "value": 8 },
    { "name": "NICOMAR GmbH Maritime Sales Services", "value": 10 },
    { "name": "Pelagus 3D", "value": 10 },
    { "name": "Pitzner Industrieservice GmbH & Co. KG", "value": 15 },
    { "name": "Sander Marine GmbH&Co.KG", "value": 10 },
    { "name": "Seacon Shipping Group Holdings Ltd", "value": 8 },
    { "name": "Seago Marine Engineering and Services Limited", "value": 10 },
    { "name": "Seaway Marine & Offshore Service Company Ltd", "value": 10 },
    { "name": "Seven Seas Group", "value": 10 },
    { "name": "STr Marine Sp. z o.o.", "value": 10 },
    { "name": "TURBOMED S.A.", "value": 15 },
    { "name": "TX Marine Messsysteme GmbH", "value": 8 },
    { "name": "Wilhelmsen Ships Service GmbH", "value": 10 }
    // Add more supplier data
  ];

  displayedColumns: string[] = ['supplier', 'performance'];
  dataSource = [
    { "supplier": "ADAMAR INTERNATIONAL MARITIME SERVICES INC", "performance": 80 },
    { "supplier": "Arthur Friedrichs Industriebedarf GmbH", "performance": 85 },
    { "supplier": "Breckle Matratzenwerk Weida GmbH", "performance": 90 },
    { "supplier": "Changchun Shipping PTE Ltd", "performance": 75 },
    { "supplier": "CIS Elektrotechnik GmbH", "performance": 88 },
    { "supplier": "Desin Marine & Industry Services Ltd.", "performance": 82 },
    { "supplier": "Drew Marine", "performance": 78 },
    { "supplier": "ELECTRICA SA Cable Electrical Material Supplier", "performance": 92 },
    { "supplier": "Fast Marine Services Denizcilik Ltd", "performance": 85 },
    { "supplier": "Fuji Trading Co., Ltd", "performance": 80 },
    { "supplier": "Hidramar Group", "performance": 87 },
    { "supplier": "KET Marine International B.V.", "performance": 90 },
    { "supplier": "Kloska GmbH, Uwe", "performance": 75 },
    { "supplier": "Ligabue S.p.A.", "performance": 88 },
    { "supplier": "LUTZ Elevators", "performance": 82 },
    { "supplier": "MEC Safety Systems GmbH", "performance": 80 },
    { "supplier": "N.Y. Co. Ltd", "performance": 85 },
    { "supplier": "NANJING CHUTUO SHIPBUILDING EQUIPMENT CO. LTD", "performance": 90 },
    { "supplier": "NICOMAR GmbH Maritime Sales Services", "performance": 75 },
    { "supplier": "Pelagus 3D", "performance": 88 },
    { "supplier": "Pitzner Industrieservice GmbH & Co. KG", "performance": 92 },
    { "supplier": "Sander Marine GmbH&Co.KG", "performance": 78 },
    { "supplier": "Seacon Shipping Group Holdings Ltd", "performance": 85 },
    { "supplier": "Seago Marine Engineering and Services Limited", "performance": 87 },
    { "supplier": "Seaway Marine & Offshore Service Company Ltd", "performance": 80 },
    { "supplier": "Seven Seas Group", "performance": 90 },
    { "supplier": "STr Marine Sp. z o.o.", "performance": 75 },
    { "supplier": "TURBOMED S.A.", "performance": 82 },
    { "supplier": "TX Marine Messsysteme GmbH", "performance": 88 },
    { "supplier": "Wilhelmsen Ships Service GmbH", "performance": 85 }
    // Add more data as needed
  ];
}