import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedMaterialModule } from '../shared-material.module';

// ALWAYS REQUIRED
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { HeaderTopComponent } from './header-top/header-top.component';
import { SharedPipesModule } from '../pipes/shared-pipes.modules';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';
import { SidebarTopComponent } from './sidebar-top/sidebar-top.component';
import { HeaderSideComponent } from './header-side/header-side.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FooterComponent } from './footer/footer.component';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PaginatedAutocompleteComponent } from './paginated-autocomplete/paginated-autocomplete.component';
import { EmbeddedLoaderComponent } from './embedded-loader/embedded-loader.component';
import { FullLoaderComponent } from './full-loader/full-loader.component';
import { PaginatedAutocompleteManualComponent } from './paginated-autocomplete-manual/paginated-autocomplete-manual.component';

const components = [
  AdminLayoutComponent,
  AuthLayoutComponent,
  AppLoaderComponent,
  ButtonLoadingComponent,
  SidenavComponent,
  SidebarSideComponent,
  SidebarTopComponent,
  HeaderTopComponent,
  HeaderSideComponent,
  BreadcrumbComponent,
  FooterComponent,
  PaginatedAutocompleteComponent,
  PaginatedAutocompleteManualComponent,
  EmbeddedLoaderComponent,
  FullLoaderComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  declarations: components,
  exports: components,
})
export class SharedComponentsModule {}
