import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.modules';
import { ThemeService } from './services/theme.service';
import { RoutePartsService } from './services/route-parts.service';
import { LocalStoreService } from './services/local-store.service';
import { NavigationService } from './services/navigation.service';
import { WebApiService } from './services/web-api.service';
import { DataTransferService } from './services/data-transfer.service';
import { SharedDirectivesModule } from './directives/shared-directives.module';

@NgModule({
  imports: [
    CommonModule,
    // MatDialogModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    DataTransferService,
    AuthGuard,
    UserRoleGuard,
    WebApiService,
  ],
  exports: [SharedComponentsModule, SharedPipesModule,SharedDirectivesModule],
})
export class SharedModule {}
