<mat-toolbar color="#42a190">
                    <span>Supplier Performance</span>
                  </mat-toolbar>
                  
                  <mat-card>
                    <ngx-charts-bar-horizontal
                      [view]="[700, 400]"
                      [scheme]="colorScheme"
                      [results]="supplierPerformanceData"
                      [gradient]="false"
                      [xAxis]="true"
                      [yAxis]="true"
                      [legend]="true"
                      [showXAxisLabel]="true"
                      [showYAxisLabel]="true"
                      [xAxisLabel]="'Performance Metrics'"
                      [yAxisLabel]="'Suppliers'">
                    </ngx-charts-bar-horizontal>
                  </mat-card>
                  
                  <mat-card>
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                      <ng-container matColumnDef="supplier">
                        <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.supplier}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="performance">
                        <mat-header-cell *matHeaderCellDef> Performance </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.performance}} </mat-cell>
                      </ng-container>
                  
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                  </mat-card>