// import { UserCookieObj } from './../../views/user/users/users.service';
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const userdetails: UserCookieObj = this.cookieService.check("user") ? JSON.parse(this.cookieService.get("user")) : null;
    const userdetails:any = this.cookieService.check("user") ? JSON.parse(this.cookieService.get("user")) : null;
    if (!this.isValid(userdetails)) {
      console.log('user not found navigating to signin')
      this.router.navigateByUrl(`/auth/signin`);
      return false;
    }

    return true;
  }

  private isValid(userdetails:any): boolean {
    if (userdetails == null || userdetails.email == null) {
      return false;
    } else {
      return true;
    }
  }
}
