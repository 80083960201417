<mat-toolbar>
  <span style="color: #74c5b5;">Supplier Categories</span>
</mat-toolbar>
<mat-card>
                    <!-- <h3>Supplier Categories</h3> -->
                    <ngx-charts-pie-chart
                      [view]="[700, 400]"
                      [scheme]="colorScheme"
                      [results]="supplierCategoryData"
                      [gradient]="false"
                      [legend]="true"
                      [labels]="true"
                      [doughnut]="false">
                    </ngx-charts-pie-chart>
                  </mat-card>
                  
                  <mat-card>
                    <h3>Supplier Categories (Donut)</h3>
                    <ngx-charts-pie-chart
                      [view]="[700, 400]"
                      [scheme]="colorScheme"
                      [results]="supplierCategoryData"
                      [gradient]="false"
                      [legend]="true"
                      [labels]="true"
                      [doughnut]="true">
                    </ngx-charts-pie-chart>
                  </mat-card>