import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppService {
    tokenObject:any = new BehaviorSubject<{}>({});
    token$:any = this.tokenObject.asObservable();

    setIntervalTime = 60000;
    //initialRender=true;

    setTokenObject(data: {}) {
        this.tokenObject.next(data);
      }
    

}