<mat-form-field class="full-width" color="accent">
    <mat-label>{{label}}</mat-label>
    <input *ngIf="control" matInput [formControlName]="controlName" [type]="inputType" [matAutocomplete]="paginatedAutocomplete"
        (click)="clickCallback(true)" name="owners" #trigger="matAutocompleteTrigger" [autocompletePosition]="trigger"
        [placeholder]="placeholder" [required]="required" [readonly]="readonly">
    <div matSuffix class="auto-complete-loader">
        <mat-progress-spinner *ngIf="gettingData" color="primary" mode="indeterminate" diameter="16" strokeWidth="2">
        </mat-progress-spinner>
    </div>
    <mat-autocomplete (opened)="autoCompleteEvent('opened')" (closed)="autoCompleteEvent('closed')"
        #paginatedAutocomplete="matAutocomplete" [displayWith]="displayWithFunc"
        (optionSelected)="selectionMadeEvent($event.option.value)">
        <mat-option *ngFor="let result of resultList" [value]="result">
            <span>{{displayWithFunc(result)}}</span>
        </mat-option>
        <mat-option *ngIf="resultList.length == 0 && paginatedAutocomplete.isOpen" [disabled]="true">
            No results found...
        </mat-option>
    </mat-autocomplete>
</mat-form-field>