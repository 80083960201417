import { Directive, HostListener, Host } from '@angular/core';
import { DropdownLinkDirective } from './dropdown-link.directive';

@Directive({
  selector: '[appDropdownToggle]'
})
export class DropdownAnchorDirective {

  constructor(@Host() protected navlink: DropdownLinkDirective) {}

  @HostListener('click', ['$event'])
  onClick(e: any) {
    this.navlink.toggle();
  }
}