<mat-sidenav-container>
  <mat-sidenav mode="side" opened style="width:20px">
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/dashboard/admin/overview']">Dashboard Overview</a>
      <a mat-list-item [routerLink]="['/dashboard/admin/performance']">Supplier Performance</a>
      <a mat-list-item [routerLink]="['/dashboard/admin/reports']">Reports</a>
      <!-- <a mat-list-item [routerLink]="['/dashboard/admin/settings']">Settings</a> -->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet> <!-- This is critical for loading child routes -->
  </mat-sidenav-content>
</mat-sidenav-container>