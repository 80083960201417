<div style="padding: 32px;">
    <h1>Select one supplier</h1>
    <div mat-dialog-content>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="selectedSupplier">
            <mat-radio-button class="example-radio-button" *ngFor="let supplierObjects of data; let i = index"
            [value]="{name: supplierObjects.companyName, index: supplierObjects.supplierId}">
                {{supplierObjects.companyName}} ({{supplierObjects.emailAddress}})
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button (click)="onClose()">Submit</button>
    </div>
</div>