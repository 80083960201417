import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { IMenuItem, NavigationService } from "../../../shared/services/navigation.service";
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { Router } from '@angular/router';
import { ImpaCacheService } from 'src/app/views/dashboard/impa/impa-cache.service';



@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  // styleUrl: './header-top.component.css'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub!: Subscription;
  menuClick: any;
  // egretThemes: any[] = [];
  // currentLang = 'en';
  // availableLangs = [{
  //   name: 'English',
  //   code: 'en',
  // }, {
  //   name: 'Spanish',
  //   code: 'es',
  // }]
  @Input() notificPanel:any;
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private router: Router,
    private impaCacheService: ImpaCacheService
  ) { }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    // this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
      let limit = 7;
      let mainItems: any[] = res.slice(0, limit);
      if (res.length <= limit) {
        this.menuItems = mainItems;
        return;
      }
      let subItems: any[] = res.slice(limit, res.length - 1);
      mainItems.push({
        name: 'More',
        type: 'dropDown',
        tooltip: 'More',
        icon: 'more_horiz',
        sub: subItems
      })
      this.menuItems = mainItems;
 
      let linkProduct = '';
      
      this.menuItems.map((item: any) => {
        if(item.name === 'Catalogue') {
          item.sub[0].state = item.state + '/' + item.sub[0]['state'] + '/' + item.sub[0].sub[0]['state'];
          this.impaCacheService.categoryList = item.sub[0].sub;
        }
      });
    })

    // this.router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   // Any additional logic when navigation ends
    // });
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe()
  }
  // setLang() {
  //   this.translate.use(this.currentLang)
  // }
  // changeTheme(theme:any) {
  //   this.layout.publishLayoutChange({ matTheme: theme.name })
  // }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
  onMenuClick(menu:IMenuItem){
    this.menuClick.emit(menu)
    console.log('menu clicked==>',menu)
    if (menu.sub) {
      this.router.navigate([`/${menu.state}`]);
    } else {
      this.router.navigate([`/${menu.state}`]);
    }
  }
}
