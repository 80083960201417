<mat-toolbar class="topbar">
    <!-- Sidenav toggle button -->
    <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()"
      matTooltipClass="mat-tooltip-custom" matTooltip="">
      <mat-icon>menu</mat-icon>
    </button>
  
    <span fxFlex></span>
  
    <!-- Top left user menu -->
    <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
      <img [src]="'images/ihm-app-logo-small.png' | environment" alt="VS" />
    </button>
  
    <mat-menu #accountMenu="matMenu">
      <button mat-menu-item [routerLink]="['/profile/settings']" [disabled]="true">
        <mat-icon>settings</mat-icon>
        <span>Account Settings</span>
      </button>
      <button mat-menu-item (click)="jwtAuth.signout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ "SIGNOUT" | translate }}</span>
      </button>
    </mat-menu>
  </mat-toolbar>