import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentPipe } from './environment.pipe';

const pipes = [
  EnvironmentPipe,
]

@NgModule({
  imports: [
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}