import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { EventEmitter, Injectable, Output } from "@angular/core";
import { WebApiService } from "src/app/shared/services/web-api.service";
import { Page } from "src/app/shared/models/page";
import { Observable } from "rxjs";
import { PagedData, PagedDataUserMenu } from "src/app/shared/models/paged-data";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { menuPageParams, PageParams } from "src/app/shared/common/Enums";
import { map } from "rxjs/operators";
import { ApiUrlConstants } from "src/app/shared/common/ApiUrlConstants";
import { UserRegistration } from "src/app/shared/models/user-registration.model";
import { UserToken } from "src/app/shared/models/user-token.model";
import { JSEncrypt } from "jsencrypt";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { FullLoaderService } from "src/app/shared/services/full-loader.service";
import { AddUserResponse, ChangeUserPasswordForm, ForgotPassForm, ForgotPasswordResponse, LoginForm, LoginResponse, NewPassAfterForgotPassForm, ResetPassForm, SignUpForm } from 'src/app/shared/models/user.model';
import { UserCategory } from 'src/app/shared/models/user-category.model';
import { UserMenu } from 'src/app/shared/models/user-menu.model';
import { UserMenuPage } from 'src/app/shared/models/user-menu-page';

export interface UserCookieObj {
  name: string;
  email: string;
}

@Injectable({
  providedIn: "root",
})
export class UsersService {
  @Output() userRecord: any = new EventEmitter();
  constructor(
    private webApiService: WebApiService,
    private cookieService: CookieService,
    private router: Router,
    private fullLoaderService: FullLoaderService,
    private navigationService: NavigationService,
    private snack: MatSnackBar) { }

  /**
   * Get all users list with paginated options.
   * @param pageMetadata Page Metadata i.e. PAGE OFFSET, PAGE SIZE, TOTAL PAGES etc..
   */
  getPaginatedUsers(pageMetadata: Page): Observable<PagedData<any>> {
    let apiUrl = "/users/";

    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, pageMetadata.size)
      .set(PageParams.page, pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, pageMetadata.serachParam)
      .set(PageParams.sortColoumn, pageMetadata.sortColoumn)
      .set(PageParams.sortOrder, pageMetadata.sortOrder);

    return this.webApiService
      .getPaginatedRequest<PagedData<any>>(apiUrl, params)
      .pipe(
        map((paginatedUser) => {
          // Set page metadata for frontend table properties
          paginatedUser.page.totalElements = paginatedUser.count;
          paginatedUser.page.size = pageMetadata.size;
          paginatedUser.page.totalPages = Math.floor(
            paginatedUser.count / pageMetadata.size
          );
          paginatedUser.page.pageNumber = pageMetadata.pageNumber;
          paginatedUser.page.serachParam = pageMetadata.serachParam;
          return paginatedUser;
        })
      );
  }

  /**
  * Get all users list with paginated options.
  * @param pageMetadata Page Metadata i.e. PAGE OFFSET, PAGE SIZE, TOTAL PAGES etc..
  */
  getPaginatedAllUsersByName(pageMetadata: Page): Observable<PagedData<any>> {
    let apiUrl = "/users/allUsers/";

    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, pageMetadata.size)
      .set(PageParams.page, pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, pageMetadata.serachParam)
      .set(PageParams.sortColoumn, pageMetadata.sortColoumn)
      .set(PageParams.sortOrder, pageMetadata.sortOrder);

    return this.webApiService
      .getPaginatedRequest<PagedData<any>>(apiUrl, params)
      .pipe(
        map((paginatedUser) => {
          // Set page metadata for frontend table properties
          paginatedUser.page.totalElements = paginatedUser.count;
          paginatedUser.page.size = pageMetadata.size;
          paginatedUser.page.totalPages = Math.floor(
            paginatedUser.count / pageMetadata.size
          );
          paginatedUser.page.pageNumber = pageMetadata.pageNumber;
          paginatedUser.page.serachParam = pageMetadata.serachParam;
          return paginatedUser;
        })
      );
  }

  /**
   * Get all users list.
   */
  getAllUsers(): Promise<any[]> {
    let apiUrl = "/users/";
    return this.webApiService.getRequest<any[]>(apiUrl);
  }

  /**
   * Get user detail by user id.
   * @param _userId User Id.
   */
  getUserById(_userId: number): Promise<any> {
    let apiUrl = `/users/${_userId}`;
    return this.webApiService.getRequest<any>(apiUrl);
  }

  getRegistrationData(): Promise<any>{
    return this.webApiService.getRequest<any>(ApiUrlConstants.getRegistrationFormData)
  }

  /**
   * Add new user.
   * @param _userDetail User form data.
   */
  addUser(_userDetail: SignUpForm): Promise<AddUserResponse> {
    return this.webApiService.postRequest<AddUserResponse>(ApiUrlConstants.registerUser, _userDetail);
  }

  /**
   * Update any existing user details by user id.
   * @param _userId To be updated user id.
   * @param _userDetail User form data.
   */
  updateUser(_userId: number, _userDetail: any): Promise<any> {
    let apiUrl = `/users/${_userId}`;
    return this.webApiService.putRequest<any>(apiUrl, _userDetail);
  }

  /**
   * Remove any existing user by user id.
   * @param _userId To be removed user id.
   */
  deleteUser(_userId: number): Promise<any> {
    let apiUrl = `/users/${_userId}`;
    return this.webApiService.deleteRequest<any>(apiUrl);
  }

  getAllCategories(): Promise<any[]> {
    return this.webApiService.getRequest<any[]>(ApiUrlConstants.GetUserCategories);
  }

  /**
   * Get user details by logged-in user's email.
   * @param _email Logged-In user's email.
   */
  getUserDetailByEmail(_email: string): Observable<UserRegistration> {
    let userDetailByEmailUrl = ApiUrlConstants.GetUserDetailByEmail;
    let requestObj = {
      email: _email,
    };
    return this.webApiService.postRequestObservable<UserRegistration>(
      userDetailByEmailUrl,
      requestObj
    );
  }

  /**
   * Get user details by logged-in user's tokenId.
   * @param _tokenId Logged-In user's tokenId.
   */
  getUserDetailByToken(_tokenId: string): Observable<UserToken> {
    let userDetailByTokenId = ApiUrlConstants.GetUserDetailByToken;
    let requestObj = {
      tokenId: _tokenId,
    };
    return this.webApiService.postRequestObservable<UserToken>(
      userDetailByTokenId,
      requestObj
    );
  }

  /**
   * Get user details by logged-in user's tokenId.
   * @param _userId Logged-In user's tokenId.
   */
  logoutCurrentUser(_userId: number): Observable<UserRegistration> {
    let apiUrl = `/users/logout/${_userId}`;
    return this.webApiService.getRequestObservable<UserRegistration>(apiUrl);
  }

  /**
   * Disable any existing user by user id.
   * @param _userId To be disable user.
   */
  disableUser(_userId: number): Promise<any> {
    let apiUrl = `/users/disableUser/${_userId}`;
    return this.webApiService.deleteRequest<any>(apiUrl);
  }

  /**
   * Enable any existing user by user id.
   * @param _userId To be enable user id.
   */
  enableUser(_userId: number): Promise<any> {
    let apiUrl = `/users/enableUser/${_userId}`;
    return this.webApiService.putRequest<any>(apiUrl, { userId: _userId });
  }

  /**
   * Change existing logged in user password.
   * @param _oldPassword To be match Old Password.
   * @param _newPassword To be set New Password.
   */
  changeUserPassword(requestObj: ChangeUserPasswordForm): Promise<any> {
    let apiUrl = `/users/changePassword`;
    return this.webApiService.postRequest<any>(apiUrl, requestObj);
  }

  /**
   * Resend temporary password to new user
   * @param requestObj Resend temporary password request payload
   * @returns Success/error observable of user-registration response.
   */
  resendTemporaryPassword(requestObj: { id: number, email: string, name: string })
    : Observable<UserRegistration> {
    let resendTemporaryPasswordUrl = ApiUrlConstants.ResendTemporaryPassword;
    return this.webApiService
      .postRequestObservable<UserRegistration>(resendTemporaryPasswordUrl, requestObj);
  }

  /**
   * Get all new users list.
   */
  getAllNewUsers(): Observable<UserRegistration[]> {
    let apiUrl = "/users/getAll/newUsers";
    return this.webApiService.getRequestObservable<UserRegistration[]>(apiUrl);
  }

  /**
   * Update Newly Create User Review By User id.
   * @param _userId To be review user id.
   */
  updateUserReview(_userId: number): Promise<any> {
    let apiUrl = `/users/updateUserReview/${_userId}`;
    return this.webApiService.putRequest<any>(apiUrl, { id: _userId });
  }

  /**
 * Encrypt plain-text into cipher-text string using RSA algorithm 
 * @param {*} ptString Plaintext string.
 * @returns Encrypted ciphertext string.
 */
  encryptPT(ptString:any) {
    try {
      var encrypt = new JSEncrypt({
        default_key_size: "2048"
      });
      encrypt.setPublicKey(environment.publicKey);
      return encrypt.encrypt(ptString);
    } catch (err) {
      //console.log("Encrypt Error", err);
      return null;
    }
  }

  /**
 * Decrypt cipher-text into plain-text string using RSA algorithm
 * @param {*} ctString Ciphertext string.
 * @returns Decrypted plaintext string.
 */
  decryptCT(ctString:any) {
    try {
      let decrypt = new JSEncrypt({
        default_key_size: "2048"
      });
      decrypt.setPrivateKey(environment.privateKey);
      let decodeURL = decodeURIComponent(ctString);
      return decrypt.decrypt(decodeURL);
    } catch (error) {
      //console.log("Descrypt Error", error);
      return null;
    }
  }

  async isDisposableEmailAddress(email:any) {
    let eMailResult = false;
    await fetch(`https://disposable.debounce.io/?email=${email}`)
      .then(response => response.json())
      .then(result => {
        eMailResult = JSON.parse(result.disposable);
        return eMailResult;
      })
      .catch(error => {
        return eMailResult;
      });
    return eMailResult;
  }

  isValidEmailDomains(email:any) {
    const freeEmailDomains = require('free-email-domains');
    for (var i = 0; i < freeEmailDomains.length; i++) {
      var domain = freeEmailDomains[i];
      if (email.indexOf(domain) != -1) {
        return false;
      }
    }
    return true;
  }

  //#region Data transfer getter/setter.
  public setUser(value: any): void {
    this.userRecord.next(value);
  }

  public getUser(): Observable<string> {
    return this.userRecord;
  }
  //#endregion

  getUserDetails(pageMetadata:any) {
    let params = new HttpParams()
      .set(PageParams.limit, pageMetadata.size)
      .set(PageParams.page, pageMetadata.pageNumber + 1)
      .set(PageParams.flag, pageMetadata._flag)
    return this.webApiService
      .getPaginatedRequest<PagedData<any>>(ApiUrlConstants.getUserDetails, params)
      .pipe(
        map((paginatedUser) => {
          // Set page metadata for frontend table properties
          paginatedUser.page.totalElements = paginatedUser.count;
          paginatedUser.page.size = pageMetadata.size;
          paginatedUser.page.totalPages = Math.floor(
            paginatedUser.count / pageMetadata.size
          );
          paginatedUser.page.pageNumber = pageMetadata.pageNumber;
          paginatedUser.page.serachParam = pageMetadata.serachParam;
          return paginatedUser;
        })
      );
  }

  loginCurrentUser(userData: LoginForm) {
    return this.webApiService.postRequest<LoginResponse>(ApiUrlConstants.currentUserLogin, userData);
  }

  resetUserPassword(userData: ResetPassForm) {
    return this.webApiService.postRequest<any>(ApiUrlConstants.resetUserPassword, userData);
  }

  forgotPassword(userData: ForgotPassForm) {
    return this.webApiService.postRequest<ForgotPasswordResponse>(ApiUrlConstants.forgotPassword, userData);
  }

  setNewPasswordFromForgot(userData: NewPassAfterForgotPassForm) {
    return this.webApiService.postRequest<any>(ApiUrlConstants.setNewPasswordFromForgot, userData);
  }


  setAllCookies(activeRoute: any) {
    this.cookieService.deleteAll("/");
    if (activeRoute && activeRoute !== null && typeof activeRoute !== undefined && activeRoute !== "") {
      this.setCookie('tokenId', activeRoute.tokenId, 1, "/", "", true, 'Lax');
      this.setCookie('tokenHash', activeRoute.tokenHash, 1, "/", "", true, 'Lax');
      console.log('test21',this.cookieService.get("tokenId"),this.cookieService.get("tokenHash"));
      //#region GET USER DETAIL FROM TOKEN ID
      // User not admin then check user type [Ship Manager | Ship Owner]
      this.getUserDetailByToken(activeRoute.tokenId)
        .subscribe((_userDetail) => {
          console.log('test',_userDetail); 
          this.setCookie('userId', <any>_userDetail?.userId, 1, "/", "", true, 'Lax');
          this.setCookie('roleId', <any>activeRoute?.roleId, 1, "/", "", true, 'Lax');
          this.setCookie('userRole', <any>activeRoute?.userRole, 1, "/", "", true, 'Lax');
          this.setCookie('email', _userDetail?.email, 1, "/", "", true, 'Lax');
          this.setCookie('isAdmin', <any>(_userDetail.userRegistrations.isAdmin), 1, "/", "", true, 'Lax');
          this.setCookie('clientId', <any>_userDetail.userRegistrations.clientId, 1, "/", "", true, 'Lax');
          console.log('test1',this.cookieService.get("clientId"));
          let userObj: UserCookieObj = {
            email: _userDetail.email,
            name: _userDetail.userRegistrations.name,
          };
          this.setCookie('user', JSON.stringify(userObj), 1, "/", "", true, 'Lax');
          this.setCookie('shipOwnerId', <any>(_userDetail?.userRegistrations?.shipOwnerId ?? null), 1, "/", "", true, 'Lax');
          this.setCookie('shipManagerId', <any>(_userDetail?.userRegistrations?.shipManagerId ?? null), 1, "/", '', true, 'Lax');

          // Assign menu items after cookies are set
          this.navigationService.assignMenuItems();

          // We will not add turnary operator here cause we WANT an error if any of these keys are undefined,
          // otherwise we will be redirected to the wrong dashboard
          let isAdmin = _userDetail.userRegistrations.isAdmin;
          let userRole = activeRoute?.userRole;
          if (isAdmin || (userRole ? userRole.trim().toLocaleLowerCase() == 'admin user' : null)) {
            //this.router.navigateByUrl(`/dashboard/admin`).then(() => {
            this.router.navigateByUrl(`/dashboard/supplier`).then(() => {
              this.fullLoaderService.hideLoader();
            });
          } else {
            //this.router.navigateByUrl(`/dashboard/owner`).then(() => {
            this.router.navigateByUrl(`/dashboard/supplier`).then(() => {
              this.fullLoaderService.hideLoader();
            });
          }
          this.showNotification(_userDetail);
        }, (_httpErrorResponse: HttpErrorResponse) => {
          //console.log(_httpErrorResponse);
        });
      //#endregion

    }

  }

  async setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict') {
    // Delete all duplicate cookies
    if (this.cookieService.check(name)) {
      this.cookieService.delete(name);
    }
    console.log('updating cookies',name);
    await this.cookieService.set(name, value, expires, path, domain, secure, sameSite);
    console.log('test3',this.cookieService.get("clientId"));
  }

  showNotification(userDetail:any) {
    let days = Number(userDetail['passwordChangeNotification']);
    let daysChange = Number(userDetail['passwordChangeDays']);
    let startDate = (userDetail['userRegistrations']['passwordChangedOn'] && typeof userDetail['userRegistrations']['passwordChangedOn'] !== undefined &&
      userDetail['userRegistrations']['passwordChangedOn'] !== "" && userDetail['userRegistrations']['passwordChangedOn'] !== null) ?
      userDetail['userRegistrations']['passwordChangedOn'] : userDetail['userRegistrations']['createdAt'];
    let totalMinutes = Math.floor(moment.duration(moment.default(new Date(), 'YYYY-MM-DD HH:mm:ss').diff(moment.default(startDate, 'YYYY-MM-DD HH:mm:ss'))).asMinutes())
    let minutes = Math.floor(days * 24 * 60);
    let minutesChange = Math.floor(daysChange * 24 * 60);
    if (totalMinutes > minutes && totalMinutes < minutesChange) {
      this.setCookie('isWarningNotification', 'true', 1, "/", "null", true, 'Lax');
      this.setCookie('notificationDays', userDetail['passwordChangeNotification'], 1, "/", "null", true, 'Lax');
      this.setCookie('isForceNotification', 'false', 1, "/", "null", true, 'Lax');

    } else if (totalMinutes > minutesChange) {
      this.setCookie('isWarningNotification', 'false', 1, "/", "null", true, 'Lax');
      this.setCookie('isForceNotification', 'true', 1, "/", "null", true, 'Lax');
      this.setCookie('notificationDays', userDetail['passwordChangeDays'], 1, "/", "null", true, 'Lax');

    } else {
      this.setCookie('isWarningNotification', 'false', 1, "/", "null", true, 'Lax');
      this.setCookie('isForceNotification', 'false', 1, "/", "null", true, 'Lax');
    }
  }

  getUserCategoryById(categoryId: number) {
    return this.webApiService.getRequest<UserCategory>(`${ApiUrlConstants.GetUserCategories}/${categoryId}`);
  }

  /** Checks whether the selected user category is valid or not */
  isValidUserCategory(_selectedUserCategory: UserCategory) {
    return new Promise<UserCategory>((resolve, reject) => {
      if (
        _selectedUserCategory !== null &&
        _selectedUserCategory !== undefined &&
        _selectedUserCategory
      ) {
        this.getUserCategoryById(Number(_selectedUserCategory.id)).then((dbUserCategory) => {
          if (
            dbUserCategory.id == _selectedUserCategory.id &&
            dbUserCategory.name == _selectedUserCategory.name
          ) {
            resolve(dbUserCategory);
          } else {
            this.snack.open(`${"Selected User Category Not Valid!"}`, "OK", {
              duration: 3000,
            });
          }
        }).catch((error: HttpErrorResponse) => {
          //console.log(error);
          this.snack.open(`${"Selected User Category Not Valid!"}`, "OK", {
            duration: 3000,
          });
          reject("user category rejected");
        });
      } else {
        //resolve(null);
        resolve;
      }
    });
  }


  /**Function related to user menu */
  getMenuById(_menuId: number): Promise<UserMenu> {
    let apiUrl = `/userCategories/userMenu/get/${_menuId}`;
    return this.webApiService.getRequest<UserMenu>(apiUrl);
  }

  updateMenu(_menuId: number, _menuDetail: UserMenu): Promise<UserMenu> {
    let apiUrl = `/userCategories/userMenu/update/${_menuId}`;
    return this.webApiService.putRequest<UserMenu>(apiUrl, _menuDetail);
  }

  addMenu(_menuDetail: UserMenu): Promise<UserMenu> {
    let apiUrl = `/userCategories/userMenu/create/`;
    return this.webApiService.postRequest<UserMenu>(apiUrl, _menuDetail);
  }

  deleteMenu(_menuId: number): Promise<UserMenu> {
    let apiUrl = `/userCategories/userMenu/delete/${_menuId}`;
    return this.webApiService.deleteRequest<UserMenu>(apiUrl);
  }

  restoreMenu(_menuId: number): Promise<UserMenu> {
    let apiUrl = `/userCategories/userMenu/restore/${_menuId}`;
    return this.webApiService.putRequest<UserMenu>(apiUrl, { _menuId: _menuId });
  }

  getPaginatedUserMenu(pageMetadata: UserMenuPage): Observable<PagedDataUserMenu<any>> {
    let apiUrl = "/userCategories/userMenu/getAll/";

    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set('id', Number(pageMetadata.id ? pageMetadata.id : null))
      .set(menuPageParams.limit, pageMetadata.size)
      .set(menuPageParams.isActive, pageMetadata.isActive)
      .set(menuPageParams.page, pageMetadata.pageNumber + 1)
      .set(menuPageParams.searchParam, pageMetadata.serachParam)
      .set(menuPageParams.sortColoumn, pageMetadata.sortColoumn)
      .set(menuPageParams.sortOrder, pageMetadata.sortOrder);

    return this.webApiService
      .getPaginatedRequest<PagedDataUserMenu<any>>(apiUrl, params)
      .pipe(
        map((paginatedUser) => {
          // Set page metadata for frontend table properties
          paginatedUser.page.totalElements = paginatedUser.count;
          paginatedUser.page.size = pageMetadata.size;
          paginatedUser.page.totalPages = Math.floor(
            paginatedUser.count / pageMetadata.size
          );
          paginatedUser.page.pageNumber = pageMetadata.pageNumber;
          paginatedUser.page.serachParam = pageMetadata.serachParam;
          return paginatedUser;
        })
      );
  }

}
