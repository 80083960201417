import {
  ErrorHandler,
  Injectable,
  Injector,
  ApplicationRef,
  ChangeDetectorRef,
} from "@angular/core";
import { FullLoaderService } from "./full-loader.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  errorCount = 0;

  constructor(protected injector: Injector, private fullLoaderService: FullLoaderService) {
    super();
  }
  // https://github.com/angular/angular/issues/17010
  override handleError(error: any) {
    let increment = 5;
    let max = 50;

    // Prevents change detection
    let debugCtx = error["ngDebugContext"];
    let changeDetectorRef =
      debugCtx && debugCtx.injector.get(ChangeDetectorRef);
    if (changeDetectorRef) changeDetectorRef.detach();

    this.errorCount = this.errorCount + 1;
    //console.log("IN ERROR HANDLER");

    // Close all open dialogs on error (loading dialogs).
    this.fullLoaderService.hideLoader();

    if (this.errorCount % increment === 0) {
      //console.log(`errorHandler() was called ${this.errorCount} times.`);
      super.handleError(error);

      if (this.errorCount === max) {
        console.log(
          `Preventing recursive error after ${this.errorCount} recursive errors.`
        );
        let appRef = this.injector.get(ApplicationRef);
        appRef.tick();
      }
    } else if (this.errorCount === 1) {
      super.handleError(error);
    }
  }
}
