<div class="sidebar-panel">
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            <!-- App Logo -->
            <div class="branding">
                <img [src]="'images/ihm-app-logo-small.png' | environment" alt="" class="app-logo">
                <p class="app-logo-text">Varuna <br><span>Sentinels</span> </p>
                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div class="sidebar-compact-switch active" [ngClass]="{remove:layoutConf.sidebarCompactToggle}"
                    (click)="toggleCollapse()" *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span>
                </div>
            </div>
            <header class="app-user MuiPaper-root-299 MuiAppBar-root-288 jss360 MuiAppBar-positionStatic-292 flex-col items-center justify-center pt-24 pb-64 MuiPaper-elevation0-302">
                <p class="app-user-name MuiTypography-root-329 username text-16 whitespace-no-wrap MuiTypography-body1-331 MuiTypography-colorInherit-351">Hi {{user.name}},</p>
                <p class="app-user-name MuiTypography-root-329 email text-13 mt-8 whitespace-no-wrap MuiTypography-body1-331 MuiTypography-colorInherit-351">{{user.email}}</p>
                <div class="MuiAvatar-root-362 MuiAvatar-circle-364 jss361 avatar app-user-photo">
                    <img alt="user photo" [src]="'images/user1.jpg' | environment" class="MuiAvatar-img-367 jss14">
                </div>
            </header>
           
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle">
            </app-sidenav>
        </div>
        
    </div>
    <div class="app-user-controls tt-color">
        <button class="text-muted w-100" mat-icon-button mat-xs-button  (click)="logOut()">
             <span class="signout-text">Sign out</span> <!-- <mat-icon>exit_to_app</mat-icon> -->
         </button>
         <!-- <div class="copyright">
             <span>Designed and Developed by
                 Varuna Sentinels BV</span>
         </div> -->
     </div>
</div>