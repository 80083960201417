import { ScriptService } from './shared/services/script.service';
 import { NavigationService } from './shared/services/navigation.service';
import { Component, OnInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { RoutePartsService } from "./shared/services/route-parts.service";
import { filter } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { ResizeService } from "./shared/services/resize.service";
import { environment } from '../environments/environment';
import { AppService } from "./app.service";
import axios from "axios";
import { LocalStoreService } from './shared/services/local-store.service';
import { staticSuppliers } from './staticData';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  appTitle = "VS-MPS";
  pageTitle = "";
  tokens: any;
  snackBarConfig: any;
  windowSize!: { width: number; height: number; };
  isAdminUser: boolean = false;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private cookieService: CookieService,
    private resizeService: ResizeService,
     private navigationService: NavigationService,
    private scriptService: ScriptService,
    private renderer: Renderer2,
    private appService: AppService,
    private ls: LocalStoreService
  ) {
    this.snackBarConfig = new MatSnackBarConfig()
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.horizontalPosition = 'center';
    this.snackBarConfig.panelClass = "custom-snack";

    setInterval(()=>{
      //Enter the api call here
      axios.get(environment.apiURL)
      
    },900000)
  }

  ngOnInit() {
    /*const zoomMarkerScriptElement = this.scriptService.loadJsScript(this.renderer,
      `${environment.assetsLink}js/zoom-marker.js`
    );
    zoomMarkerScriptElement.onload = () => { };*/

    this.navigationService.assignCountries()

    const hammerScriptElement = this.scriptService.loadJsScript(this.renderer,
      `${environment.assetsLink}js/hammer.min.js`
    );
    hammerScriptElement.onload = () => {  };

    const jqueryMouseweelScriptElement = this.scriptService.loadJsScript(this.renderer,
      `${environment.assetsLink}js/easy-loading.js`
    );
    jqueryMouseweelScriptElement.onload = () => { };

    const dragscrollScriptElement = this.scriptService.loadJsScript(this.renderer,
      `${environment.assetsLink}js/dragscroll.js`
    );
    dragscrollScriptElement.onload = () => {  };

     document.documentElement.style.setProperty("--signup2-section-left-bg-img", `url(${environment.assetsLink + 'images/bg.png'})`);
     document.documentElement.style.setProperty("--cancel-image-scaffolding", `url(${environment.assetsLink + 'images/cancel.png'})`);
     document.documentElement.style.setProperty("--home-bg-black", `url(${environment.assetsLink + 'images/home-bg-black.png'})`);
     document.documentElement.style.setProperty("--signup3-trianglify", `url(${environment.assetsLink + 'images/backgrounds/trianglify-1.png'})`);
     document.documentElement.style.setProperty("--signup4-waterfall", `url(${environment.assetsLink + 'images/backgrounds/waterfall.jpg'})`);
    if (this.cookieService.get("tokenId") != null
      && this.cookieService.get("tokenId") != ""
      && typeof this.cookieService.get("tokenId") != "undefined") {

      // User not admin then check user type [Ship Manager | Ship Owner]
      let isAdmin = this.cookieService.get("isAdmin") ? JSON.parse(this.cookieService.get("isAdmin")) : false;
      let userRole = this.cookieService.get("userRole") ? this.cookieService.get("userRole") : null;
      this.resizeService.getWindowSize();

       this.navigationService.assignMenuItems();

      let windowRoute = window.location.href
        .split(window.location.origin)
        .pop();
      if (windowRoute === "/") {
        if (isAdmin || (userRole ? userRole.trim().toLocaleLowerCase() == 'admin user' : null)) {
          //this.router.navigateByUrl(`/dashboard/admin`);
          this.router.navigateByUrl(`/dashboard/supplier`);
        } else {
         // this.router.navigateByUrl(`/dashboard/owner`);
         this.router.navigateByUrl(`/dashboard/supplier`);
        }
      }
    }

     this.changePageTitle();

     this.ls.setItem('suppliersList',staticSuppliers);
  }

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        var routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) return this.title.setTitle(this.appTitle);
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
