<div class="app-admin-wrap" [dir]="layoutConf.dir??'auto'">
    <!-- Header for top navigation layout -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
    <app-header-top *ngIf="layoutConf.navigationPos === 'top'" [notificPanel]="notificationPanel">
    </app-header-top>
    <!-- Main Container -->
    <mat-sidenav-container [dir]="layoutConf.dir??'auto'"
      class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf.sidebarColor}} topbar-{{layoutConf.topbarColor}} footer-{{layoutConf.footerColor}}"
      [ngClass]="adminContainerClasses" [hasBackdrop]="showBackdrop">
      <mat-sidenav-content>
        <!-- SIDEBAR -->
        <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
        <app-sidebar-side *ngIf="layoutConf.navigationPos === 'side'" (mouseenter)="sidebarMouseenter($event)"
          (mouseleave)="sidebarMouseleave($event)"></app-sidebar-side>
        <!-- Top navigation layout (navigation for mobile screen) -->
        <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
        <app-sidebar-top *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"></app-sidebar-top>
  
        <!-- App content -->
        <div class="main-content-wrap" id="main-content-wrap"
          disabled="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
          <!-- Header for side navigation layout -->
          <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
          <app-header-side class="mobile-menu" *ngIf="layoutConf.navigationPos === 'side'"
            [notificPanel]="notificationPanel">
          </app-header-side>
          <div *ngIf="isWarningNotification || isForceNotification" class="alert alert-dismissible fade show" role="alert"
            [ngClass]="isWarningNotification ? 'alert-warning' : ' alert-danger'">
            You have last changed your password {{notificationDays}} days ago. For security reason, please change you
            current password as soon as possible. <a (click)="navigateToURL()" class="alert-link text-right">Click here to
              change your password</a>
            <button type="button" class="close" (click)="closeNotification()" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="rightside-content-hold" id="rightside-content-hold"
            disabled="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
            <!-- View Loader -->
            <div class="view-loader" *ngIf="isModuleLoading" style="position: fixed;" fxLayout="column"
              fxLayoutAlign="center center">
              <div class="spinner">
                <div class="double-bounce1 mat-bg-accent"></div>
                <div class="double-bounce2 mat-bg-primary"></div>
              </div>
            </div>
            <!-- Breadcrumb -->
            <app-breadcrumb></app-breadcrumb>
            <!-- View outlet -->
            <div class="container-dynamic">
              <router-outlet></router-outlet>
            </div>
            <span class="m-auto" *ngIf="!layoutConf.footerFixed"></span>
            <app-footer *ngIf="!layoutConf.footerFixed" style="margin: 0 -0.33rem -0.33rem;"></app-footer>
          </div>
          <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
          <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
        </div>
      </mat-sidenav-content>
  
      <!-- Notification bar -->
      <mat-sidenav style="z-index: 4" #notificationPanel mode="over" class="" position="end" disableClose="true">
        <div class="nofication-panel" fxLayout="column">
          <router-outlet name="form-outlet" (activate)="notificationPanel.open(); showBackdrop = true"
            (deactivate)="notificationPanel.close(); showBackdrop = false"></router-outlet>
        </div>
      </mat-sidenav>
  
      <!-- Dashboard side nav -->
      <mat-sidenav style="width: 100vw; overflow: auto" #dashboardSideNav mode="over" class="" position="start"
        disableClose="true">
        <div class="dashboard-panel">
          <div class="dashboard-panel-header">
            <button class="panel-close-button" mat-icon-button (click)="closeDashboardSideNav()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="dashboard-panel-outlet-container">
            <router-outlet name="dashboard-sidenav-outlet" (activate)="dashboardSideNav.open(); getDashboardSideNavBackLink()"
              (deactivate)="dashboardSideNav.close()"></router-outlet>
          </div>
        </div>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>