import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-embedded-loader',
  templateUrl: './embedded-loader.component.html',
  styleUrls: ['./embedded-loader.component.css']
})
export class EmbeddedLoaderComponent {
  @Input() show: boolean = true;
}
