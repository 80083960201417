// cache.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImpaCacheService {
  private categoryListObj: any[] = [];
  
  set categoryList(categoryListObj: any[]) {
    this.categoryListObj = categoryListObj;
  }

  get categoryList() {
    return this.categoryListObj;
  }
}
