import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[whiteSpaceValidate]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: WhiteSpaceValidateDirective,
    multi: true
  }]
})
export class WhiteSpaceValidateDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): {[key: string]: any} | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whiteSpace': true };
  }

}
