<!-- <input *ngIf="control" matInput [formControlName]="controlName" [type]="inputType" [matAutocomplete]="paginatedAutocomplete"
    (click)="clickCallback(true)" name="owners" #trigger="matAutocompleteTrigger" [autocompletePosition]="trigger"
    [placeholder]="placeholder" [required]="required" [readonly]="readonly">
<div matSuffix class="auto-complete-loader">
    <mat-progress-spinner *ngIf="gettingData" color="primary" mode="indeterminate" diameter="16" strokeWidth="2">
    </mat-progress-spinner>
</div>
<mat-autocomplete (opened)="autoCompleteEvent('opened')" (closed)="autoCompleteEvent('closed')"
    #paginatedAutocomplete="matAutocomplete" [displayWith]="displayWithFunc"
    (optionSelected)="selectionMadeEvent($event.option.value)">
    <mat-option *ngFor="let result of resultList" [value]="result">
        <span>{{displayWithFunc(result)}}</span>
    </mat-option>
    <mat-option *ngIf="resultList.length == 0 && paginatedAutocomplete.isOpen" [disabled]="true">
        No results found...
    </mat-option>
</mat-autocomplete> -->

<!-- <div class="input-container"> -->
    <!-- Input Field -->
    <input *ngIf="control" matInput [formControlName]="controlName" [type]="inputType"
      [matAutocomplete]="paginatedAutocomplete" (click)="clickCallback(true)" name="owners"
      [placeholder]="placeholder" [required]="required" [readonly]="readonly" class="custom-input">
  
    <!-- Spinner inside the input field -->
    <!-- <div *ngIf="gettingData" class="spinner-container">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="16" strokeWidth="2"></mat-progress-spinner>
    </div> -->
<!-- </div> -->
  
    <!-- Autocomplete -->
    <mat-autocomplete #paginatedAutocomplete="matAutocomplete" [displayWith]="displayWithFunc"
      (optionSelected)="selectionMadeEvent($event.option.value)">
      <mat-option *ngFor="let result of resultList" [value]="result">
        {{ displayWithFunc(result) }}
      </mat-option>
      <mat-option *ngIf="resultList.length === 0" disabled>
        No results found...
      </mat-option>
    </mat-autocomplete>
