import { FullLoaderService } from './../../services/full-loader.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-loader',
  templateUrl: './full-loader.component.html',
  styleUrls: ['./full-loader.component.css']
})
export class FullLoaderComponent implements OnInit {
  show: boolean = false;
  message!: string;

  constructor(private fullLoaderService: FullLoaderService) { }

  ngOnInit(): void {
    this.fullLoaderService.getLoaderStateObservable().subscribe((value) => {
      this.show = value.state;
      this.message = value.message? value.message : "";
    });
  }
}
