import { Observable } from 'rxjs';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Page } from '../../models/page';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ControlContainer, FormGroupDirective, FormControl, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-paginated-autocomplete',
  templateUrl: './paginated-autocomplete.component.html',
  styleUrls: [],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class PaginatedAutocompleteComponent implements OnInit, OnDestroy {
  resultList: any[] = [];
  gettingData: boolean = false;
  page: Page = new Page;

  @Input() required: boolean = false;
  @Input() inputType: string = "text";
  @Input() dataSrc!: (pageMeta: Page) => Observable<any>;
  @Input() displayWithFunc = (item?: any) => {
    return item ? item : undefined;
  }
  @Input() controlName!: string;
  @Input() readonly!: boolean;
  @Input() label!: string;
  @Input() control!: FormControl;
  @Input() placeholder: string = '';
  @Input() optional: boolean = false;
  @Input() pageSize: number = 10;
  @Input() onlyValidData?: boolean = false
  autocompleteSelectionVal: any;
  @Output() autoCompleteOptionSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('paginatedAutocomplete') autoCompleteInstance!: MatAutocomplete;
  @Output() isAutocomplete: EventEmitter<boolean> = new EventEmitter();

  memoizedDataSrc: any;
  memoizedResults:any = {};
  constructor() {

  }

  ngOnInit(): void {
    if (!this.control) {
      console.log('Form control is not defined');
      // Other initialization logic
      return
    } 

    if(this.control.value?.currencyCode){
      console.log('setting defualt value==>',this.control.value?.currencyCode)
      this.autocompleteSelectionVal = this.control.value?.currencyCode
      this.autoCompleteOptionSelected.emit(this.control.value);
    }

    this.page.size = this.pageSize;
    // function that takes a function and returns a function
    const memoize = (func:any) => {
      // return a function for the cache of results
      return async (...args:any) => {
        // a JSON key to save the results cache
        const argsKey = JSON.stringify(args);
        // execute `func` only if there is no cached value of clumsysquare()
        if (!this.memoizedResults[argsKey]) {
          // store the return value of clumsysquare()
          await new Promise((resolve) => {
            func(...args).subscribe((res: any) => {
              this.memoizedResults[argsKey] = res;
              resolve(res);
            });
          });
        }
        // return the cached results
        return this.memoizedResults[argsKey];
      };
    };

    this.memoizedDataSrc = memoize(this.dataSrc);

    this.control.valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.resetAndClearStoredResults();
      }),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      if (!this.control) {
        return;
      }
      this.page.pageNumber = 0;
      this.page.totalElements = 0;
      this.page.totalPages = 0;

      if (this.control.pristine) {
        // The control was marked pristine, it must have been reset, we need to remove stored results
        this.resetAndClearStoredResults();
      }
      if (this.onlyValidData && value && typeof value !== 'object') {
        this.autocompleteSelectionVal = undefined;
        this.control.setErrors({ "autocompleteOptionNotSelected": true });
        // this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }

      if (!value || typeof value == "undefined" || value == "") {
        this.page.serachParam = "";
        this.autoCompleteOptionSelected.next(null);
        this.clickCallback(false, false);
      } else {
        if (typeof value == "string") {
          this.page.serachParam = value;
          this.clickCallback(false, false);
        }
      }
    });
  }

  /** This can be improved a lot with memoization, need to implement in future */
  clickCallback(reset: boolean = false, concat: boolean = true) {
    if (!this.control) {
      console.error('FormControl is not defined.');
      return;
    }

    if (reset) {
      this.resultList = [];
      this.page.pageNumber = 0;
      this.page.totalElements = 0;
      this.page.totalPages = 0;
      this.page.serachParam = this.control.value ? this.displayWithFunc(this.control.value) : "";
    }

    // Call already in progress
    if (this.gettingData) {
      //console.log("A previous call is already in progress!");
    } else {
      this.gettingData = true;
      this.memoizedDataSrc(this.page)?.then((data: any) => {
        this.resultList = concat ? this.resultList.concat(data.rows) : data.rows;
        this.page.totalElements = data.page?.totalElements;
        // Returns pages as if they start from 0
        this.page.totalPages = data.page?.totalPages ? data.page.totalPages + 1 : undefined;
        this.gettingData = false;
      }, ((error: any) => {
        this.gettingData = false;
        //console.log("Something went wrong", error);
      }));
    }
  }

  autoCompleteEvent(type = "opened" || "closed") {
    // Need to give sometime for panel to load into the DOM
    setTimeout(() => {
      const panel = this.autoCompleteInstance.panel?.nativeElement;
      const scrollfunction = () => {
        if (panel.scrollHeight - panel.scrollTop === panel.clientHeight) {
          this.page.pageNumber++;
          this.clickCallback();
        };
      }

      if (type == "opened" && panel) {
        panel.addEventListener('scroll', scrollfunction);
      } else if (type == "closed" && panel) {
        panel.removeEventListener('scroll', scrollfunction);
        this.resultList = [];
      }
    }, 200);
  }

  selectionMadeEvent(option: any) {
    if (!this.control) {
      console.error('FormControl is not defined.');
      return;
    }
    this.autocompleteSelectionVal = option;
    this.control.setErrors({
      "autocompleteOptionNotSelected": false
    });
    this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.autoCompleteOptionSelected.emit(option);
  }

  ngOnDestroy(): void {
  }

  resetAndClearStoredResults() {
    this.memoizedResults = {};
    this.resultList = [];
  }
}
