export const staticSuppliers:{
    id:number,
    name: string,
    logo: string,
    description: string
}[] = [
    {
      id: 1,
      name: 'ADAMAR INTERNATIONAL MARITIME SERVICES INC',
      logo: 'assets/StaticSupplierslogo/ADAMAR INTERNATIONAL MARITIME SERVICES INC.png',
      description:`Adamar International Maritime Services Inc, is a general ship supplier & technical services provider and marine valves manufacturer/ stockiest based in Türkiye with subsidiaries in Rotterdam & China.
      Adamar is an ISSA, IMPA, TURSSA and TRADE NET member. Being certified with ISO9001, ISO22000, ISO14001 and ISO45001 we run our business in full compliance with international regulations and quality, safety & environmental standards. We are committed to providing you with best products & service at the lowest prices & punctual delivery.
      
      The company was founded in 2003, in Aliaga/Izmir by Mr. Taner Topkara, who has been servicing as its President since then. In years, thanks to the commitment of our qualified & experienced staff, whose hard work has enabled us to quickly adapt to the ever-changing demands in the ship supply industry, we proudly managed to become a sector leader in the region and also expanded our presence worldwide. Nowadays, we own 2 main offices in Izmir and Istanbul, and 3 branch offices in Zeyport (Bosphorus Strait), Mersin and Iskenderun. We presently operate 6 warehouses worldwide with enclosed capacity over 15000 m2 located in Izmir, Istanbul, Dilovasi, Mersin, China and Rotterdam; a new warehouse with capacity of 4000 m2 is under construction in Izmir. Our international presence and operations are supported by our subsidiaries in China, Hong Kong, Greece and the Netherlands, who are managed by our own employees only.
      
      Since our foundation, we aimed to increase our capacity and service level with new investments and use of modern technology, to sustain and reinforce our position in the marine supply sector and become a global player.
      
      To meet the ongoing market demands and provide expert, cost- effective & timely solution to your problems, over the last few years we made significant investments in our physical capacity, stock capacity, manpower capacity and associate supplies & services such as Marine Valves, Electrical Cables, Technical Services & Repairs, Workshop, IT Division, Marine Logistics Division and Specialized Shipyard Attendance Division.
      
      A team of 500 dedicated employees, many with strong marine background, is ready to support you 24/7 everywhere in Turkiye with our high quality, cost effective products and on-time deliveries.
      `
    },
    {
      id: 2,
      name: 'Arthur Friedrichs Industriebedarf GmbH',
      logo: '',
      description:`Marine Mattresses by Breckle. That’s over 90 years of experience as one of Germany’s leading mattress manufacturers. Our family business has been producing mattresses, bed frames, upholstered and box spring beds as well as quilting products since 1932. The production is 100% Made in Germany in our facilities in Thuringia and Baden-Wuerttemberg (3 locations).
      Sleeping comfort combined with quality and sustainability are our credo.
      You will find our products in well-known furniture stores, mattress shops and stores, as well as in online shipping.
      
      For ship equipment we offer a wide range of MED/IMO certified mattresses and accessories. Well-known shipping companies such as AIDA Cruises, Hapag Lloyd Cruises, Viking Cruises, Phoenix Reisen and many more already trust us.
      You will find products adapted to your needs including all-round service. We have our own truck fleet and deliver to you all the way to the ship dock and Europe-wide incl. Customs. Short delivery times even for large quantities are guaranteed.
      `
    },
    {
      id: 3,
      name: 'Breckle Matratzenwerk Weida GmbH',
      logo: '',
      description:`Marine Mattresses by Breckle. That’s over 90 years of experience as one of Germany’s leading mattress manufacturers. Our family business has been producing mattresses, bed frames, upholstered and box spring beds as well as quilting products since 1932. The production is 100% Made in Germany in our facilities in Thuringia and Baden-Wuerttemberg (3 locations).
      Sleeping comfort combined with quality and sustainability are our credo.
      You will find our products in well-known furniture stores, mattress shops and stores, as well as in online shipping.
      
      For ship equipment we offer a wide range of MED/IMO certified mattresses and accessories. Well-known shipping companies such as AIDA Cruises, Hapag Lloyd Cruises, Viking Cruises, Phoenix Reisen and many more already trust us.
      You will find products adapted to your needs including all-round service. We have our own truck fleet and deliver to you all the way to the ship dock and Europe-wide incl. Customs. Short delivery times even for large quantities are guaranteed.
      `
    },
    {
      id: 4,
      name: 'Changchun Shipping PTE Ltd',
      logo: '',
      description:`CHANGCHUN is a leading international shipping agency founded in 2016 by its founder Mr. GUO CHANGCHUN, The company is headquartered in Shanghai, with fully owned operational branch offices in Dalian, Zhoushan, Nantong and Guangzhou as well as
      in Singapore.
      
      Since its establishment, with a passion to serve our ever-increasing clientele CHANGCHUN has grown substantially with a network of sub agencies at all ports and shipyards in China.
      
      As a one-stop solution marine service provider with years of experience, CHANGCHUN has a total investment 50 million CNY and 88 employees. We provide personalized attention to individua
      ship owners and their ships, resulting in a level of satisfaction rarely seen in the China market. With our intensive attention to details, which ensures customer satisfaction, CHANGCHUN has developed strong long-term relationships with EASTERN PACIFIC SHIPPING MSC, CMA CGM, EVERGREEN, UNIX LINE, SAFE BULKERS, MODEC and YINSON, etc.
      
      CHANGCHUN's International Ship Agency Service Management System has been approved and certified by DNV-GL, including Quality Management System Standard GB/T 19001-2016/1S09001:2015, Environmental Management System Standard: ISO14001:2015 and Occupational Health and Safety Management System Standard: lSO45001:2018.In addition, CHANGCHUN Ship Repair and Spare Parts Supply have been approved and certified by LRQA in accordance with ls0 9001:2015/GB/T 19001-2016.`
    },
    {
      id: 5,
      name: 'CIS Elektrotechnik GmbH',
      logo: 'assets/StaticSupplierslogo/IS Elektrotechnik GmbH.png',
      description:`CIS Elektrotechnik GmbH was founded in 1993 and specialises in the supply of electrical marine equipment for shipyards, ship owner and subcontractors.
      CIS has representations and cooperations in Europe, USA and Asia. Through the various activities CIS is in the position to operate worldwide.
      Flexible co-operation with our costumers and suppliers is a good basis for successful products and high quality.
      In addition CIS can offer commissioning, service and spare parts.
      Since 1994 CIS participate at the SMM fair in Hamburg and other exhibitions like Marintec China, Europort at Rotterdam.
      In 2009 CIS has moved to new premises, including warehouse facilities and will continue to provide excellent customer services from this new base of operations.
      `
    },
    {
      id: 6,
      name: 'Desin Marine & Industry Services Ltd.',
      logo: '',
      description:`Desin Marine Services International Limited is a globe marine spare parts supplier and one-stop marine engine service expert. The company is committed to build a global, intelligent and information-based marine system platform, providing profession and efficient service for spare supply and engine service worldwide.
      Since its establishment, DESIN has build a professional team for spare parts supply and logistics distribution, engine repair and maintenance, professional technical consultation.
      Enjoying experienced technicians and engineers, Desin offers customers inspection and maintenance services on board by her technical team. We provided troubleshooting, repairing and recondition for both diesel engines and turbo chargers. Wide range of recondition services is available in Desin as well, including following components: piston crown, cylinder cover, exhaust valve spindle and valve seat,
      crankshaft, turbo charger, ICU, fiva valve and etc. Our services area covers main ports in China, Korea, Singapore, U.A.E., USA, Germany, U.K., Greece and etc.
      `
    },
    {
      id: 7,
      name: 'Drew Marine',
      logo: 'assets/StaticSupplierslogo/Drew Marine.png',
      description:`Drew Marine is a global leader in providing essential maritime products and services designed to enhance the efficiency, safety, and sustainability of vessel operations worldwide. Our expertise spans multiple key areas:

      Technical Support and Efficient Ease of Business
      Crew Health and Wellness
      Treated Equipment - Asset Protection
      Fuel Performance and Efficiency
      Cargo Hold and Tank Cleaning
      Onboard Maintenance, Repair, and Compressed Gases
      We are committed to driving positive global change through our Environmental, Social, and Governance (ESG) initiatives, contributing to a more sustainable maritime future.
      
      Key Areas of Expertise:
      
      Water Treatment: We unlock excellence in asset protection through monthly reviews, real-time feedback, dedicated service engineers, and remote assistance.
      Fuel Management: Our advanced chemistries treat ISO 8217:2024 bio-based distillate and residual fuel blends.
      Cargo Hold and Tank Cleaning Solutions: We offer MARPOL Annex V compliant cargo hold cleaners and equipment designed for the removal of specific cargo residues.
      Welding: We support safety surveys with globally available welding consumables and safety equipment.
      Refrigeration: We supply a diverse range of refrigerants designed for optimized performance and safety in marine applications.
      Maintenance Cleaners: Our maintenance solutions are backed by technical expertise, providing effective degreasing, descaling, and all-purpose cleaning to support crew welfare and safety in engine rooms and accommodation spaces.
      Drew Marine's tailored solutions ensure that we meet the unique needs of our clients, ensuring the longevity and efficiency of their operations.
      `
    },
    {
      id: 8,
      name: 'ELECTRICA SA Cable Electrical Material Supplier',
      logo: 'assets/StaticSupplierslogo/ELECTRICA SA Cable Electrical Material Supplier.png',
      description:`Electrica SA is a leading provider of electrical products and solutions, dedicated to serving the evolving needs of the marine industry. Since 1972, we have established ourselves as a trusted partner renowned for our commitment to excellence, innovation, and customer satisfaction.

      At Electrica SA, we offer a diverse range of high-quality electrical marine products meticulously designed to meet the stringent demands of marine applications. From high-performance cables and cutting-edge sealing and installation materials to robust marine switchboards/panels and power, automation and control systems, and everything in between, our extensive product portfolio caters to the varied requirements of vessels, ensuring optimal performance and reliability in any maritime environment.
      
      Throughout the 50 years of our presence, we've built an extensive global network of key suppliers and technical partners to address the complex challenges faced by the shipping industry, optimizing performance, efficiency, and sustainability. Our commitment to excellence, ensures that our clients remain at the forefront of industry advancement, poised for long-term success in the rapidly evolving maritime landscape.
      `
    },
    {
      id: 9,
      name: 'Fast Marine Services Denizcilik Ltd',
      logo: 'assets/StaticSupplierslogo/Fast Marine Services Denizcilik Ltd.png',
      description:`Fast Marine Services, based in Istanbul, Turkey, specializes in comprehensive ship repair solutions with engineering base. Our core services include Technical Supply, Sihp Repair Services, Spare Part Supply, Shipyard Agency and Project Management. We pride ourselves on our expertise in Propeller Shaft and Rudder Systems repair and overhaul, Mechanical Repairs, Hydraulic Repairs, Boiler Repairs, Steel & Pipe Repairs, Tank Level-Gauging Gas Detection, Fire Alarm systems, Odme, OWS, NG systems repair and certification, GMDSS Survey, VDR/S-VDR APT, Gyro Overhaul, Compass Adjustment, Radio Surveys, urgent afloat and port repairs, underwater services and anode manufacturing.

      Additionally, we maintain strong relationships with all shipyards in Turkey, enabling us to secure urgent docking slots in Turkish or neighboring country shipyards. Fast Marine Services is also the Turkey representative for the famous Danish repair company MarineShaft®, MMG-Propeller from Germany, and the Turkey agency for MIKASA Industrial from Japan, Cetasol Energy Optimization from Sweden, UMSG from the Netherlands. Akrit Engineering from United Kingdom.
      
      Our mission is to ensure complete client satisfaction by delivering high-quality products and services at reasonable prices and within the promised timeframe. Our prompt and personalized response has earned us a reputable position in the marine market, and we are committed to being the fastest, most qualified, and reliable marine service provider for all ship owners' needs.
      `
    },
    {
      id: 10,
      name: 'Fuji Trading Co., Ltd',
      logo: '',
      description:`Founded in 1953, we celebrated the 70th anniversary last year. Through Fuji Trading's Network, we aim at further enhancement and evolution of our quality services to satisfy your needs and requirements at your global logistics partner, who can procure machinery equipment/ spare parts/ stores/ provisions required in the marine/ offshore industries and can provide comprehensive services by taking advantage of our functions of engineering, safety inspection/ maintenance and logistics.`
    },
    {
      id: 11,
      name: 'Hidramar Group',
      logo: '',
      description:`Hidramar Group is a Spanish holding company with two strategically located shipyards in the Canary Islands, Spain: Hidramar Shipyards at the Port of Reina Sofía in Gran Canaria and Tenerife Shipyards in Santa Cruz de Tenerife Port as well as Granadilla Port. Both facilities are situated within a Free-Trade Zone at the crossroads of key maritime routes connecting Europe, Africa, and the Americas. As the European Union's last major port near West Africa, our location offers unparalleled access and convenience.

      Since 1989, we have been providing a comprehensive range of shipyard services on the Canary Islands, including technical support, engineering, fabrication, and mobilization/demobilization operations, among many others. Our extensive experience in hydraulics guarantees exceptional ship repair and maintenance, supported by our in-house expertise in hydraulic systems and over 75 advanced welding procedures. We consistently deliver high-quality, efficient results across every project.
      
      In addition to our shipyards, Hidramar International plays a crucial role within our organization, offering specialized ship repair services to the vessels during transit. This ensures that our Valued Clients can perform crucial repairs, retrofitting, and maintenance without experiencing any downtime. We proudly stand as "Your Real Shipyard in a Box in the Atlantic Ocean."
      `
    },
    {
      id: 12,
      name: 'KET Marine International B.V.',
      logo: '',
      description:`KET Marine specializes entirely in supplying OEM & Genuine spare parts and repair services for GEA Westfalia and Alfa Laval separators.

      Since the founding of the company, KET Marine has strived to achieve an honorable track record when it comes to providing our customers with outstanding quality and reliable service solutions.
      
      Our stock program consists of approximately 30.000 different OEM & Genuine spare parts, brought to you by a staff of dedicated people that er determined to provide you with everything you need to keep your separators up and running!
      `
    },
    {
      id: 13,
      name: 'Kloska GmbH, Uwe',
      logo: 'assets/StaticSupplierslogo/Kloska GmbH, Uwe.png',
      description:`In 1981 the company Uwe Kloska GmbH was founded as a technical supplier in Bremen. Today it is still the company group’s headquarter. Over the course of several years other companies joined the group, each completing the service portfolio in a different way.

      In the beginning of the 90s the Kloska Group started to expand considerably. It was always a strategic target to be present at all important maritime locations in Germany. Following the requirements of the growing ship supply market, the group established new branches and cooperation in Belgium, the Netherlands, China, Poland, Spain, Brazil, Singapore, South Africa and the United Arab Emirates.
      
      Nowadays, the group’s business activities range from technical supplies for the shipping, on-/offshore services, construction, handicraft and general industry to engine spare parts combined with in-house repair shops, conveyor technology and hydraulics. The group also established catering services and supplies general provisions and stores to ferries, cargo vessels, cruise ships, research vessels and research stations.
      
      We extend our standard business fields by a large number of attractive specialized services. The vast range of products at our facilities includes approximately 130.000 different items.
      
      Our standard activities include delivery on short notice, 24-hours service and just-in-time delivery as well as a professional, individual and personal assistance on site. It is our highest priority to establish a long lasting customer relationship on a fair and solid basis.
      Our experienced team is used to thinking customer orientated. We benefit from our flexibility and creativity in order to meet all the frequently changing demands of the market.
      `
    },
    {
      id: 14,
      name: 'Ligabue S.p.A.',
      logo: 'assets/StaticSupplierslogo/Ligabue S.p.A..png',
      description:`Ligabue Group is a historical player in the food and service sector, specialized in services for maritime and energy industry markets. We take care of supplies, hotel services, catering, facility management and equipment at a global level, by sea and by land, for people travelling, working and living in the most remote places of the world. From 1919 to today, wherever you are going, wherever you are, Ligabue Group has the best solution for you.

      Provision and Stores Management Services
      Ligabue Group is your best partner for ship supply and provision and stores catering for merchant vessels.
      
      Industrial Services
      Thanks to our experience in on - and offshore remote site catering and hospitality services, we make the lives of oil and gas workers more comfortable.
      
      Maritime Services
      Ligabue Group specializes in every aspect of catering and hospitality services for ocean and river cruises & ferries.
      `
    },
    {
      id: 15,
      name: 'LUTZ Elevators',
      logo: '',
      description:`Since 1927, the Reinbek-based company LUTZ Elevators has been developing innovative elevator systems for a wide variety of living environments – on land and at sea. Safety, aesthetics and design are the focus here. In addition to building solutions, LUTZ Elevators is now one of the world's leading companies in the field of marine elevators. In addition to planning, manufacturing, installation, modernization and repair, the portfolio also includes the sale of elevator systems and manufacturer-independent services. The company currently employs 300 people at eight national and international locations.`
    },
    {
      id: 16,
      name: 'MEC Safety Systems GmbH',
      logo: 'assets/StaticSupplierslogo/MEC Safety Systems GmbH.png',
      description:`Since 1988, MEC has been leading the charge in providing innovative solutions aimed at enhancing cargo safety, reliability, and efficiency for container and roll-on/roll-off ships. Rooted in our firm belief that a blend of expertise, passion, flexibility, and top-notch service forms the bedrock for effectively tackling global challenges and ensuring sustained success, we're proud to operate from our strategically positioned facilities in Hamburg, Singapore, and our presence in China, particularly for the equipment of new ship construction for container and roll-on/roll-off vessels. From these key locations, we extend our comprehensive support and after-sales service to clients worldwide, ensuring their needs are met with precision and efficiency. Our extensive product portfolio, coupled with a diverse array of supporting services, enables us to cater to a broad spectrum of maritime requirements. Certified to ISO 9001 and other esteemed standards, we are unwavering in our commitment to upholding the highest standards of quality across all our offerings. Clients benefit immensely from our holistic "one-stop shop" approach, seamlessly covering every aspect of the process, from initial brainstorming and design to prototyping, production, quality control, and transportation – all managed under one accountable source. In addition to our standard lashing products, we specialize in crafting bespoke solutions tailored to meet the unique requirements of our customers. This personalized approach ensures that each solution is precisely aligned with the specific needs and challenges faced by our clients, further enhancing their operational efficiency and cargo security. With our expanding presence in the shipbuilding sector in Asia, particularly in China, we are actively involved in supplying cutting-edge equipment for the construction of container and roll-on/roll-off vessels in the region.`
    },
    {
      id: 17,
      name: 'N.Y. Co. Ltd',
      logo: '',
      description:`N.Y. Co., Ltd, headquartered in Minato City, Tokyo, is a leading provider of marine products and services tailored to meet the diverse needs of our clientele. With our extensive experience and expertise in the maritime industry, we have established ourselves as a trusted partner for shipowners, operators, and marine professionals worldwide.Our core business revolves around offering a comprehensive range of products and services for vessels of all types and sizes. From essential equipment and spare parts to cutting-edge technologies and innovative solutions, We are committed to enhancing the efficiency, safety, and sustainability of maritime operations.

      In recent years, we have intensified our focus on in-house product development, resulting in groundbreaking offerings that address the evolving challenges faced by the shipping industry. One of our flagship products is the "Beecle," a next-generation on-board service cart that has gained significant traction among car carrier owners. This environmentally conscious work vehicle exemplifies our commitment to sustainability and operational excellence.The Beecle is designed to streamline on-board logistics and improve the overall efficiency of vessel operations. Its eco-friendly features include energy-efficient propulsion systems and the use of recyclable materials, aligning with the global maritime industry's push towards reduced environmental impact.
      
      Another innovative product in our portfolio is the "SHiP+AiD" repair material kit. This comprehensive solution is engineered to address a wide array of vessel repair needs, from minor maintenance tasks to more complex structural issues. The SHiP+AiD kit contains a carefully curated selection of high-quality repair materials, and detailed instructions, enabling crew members to perform timely and effective repairs while at sea. This product not only enhances vessel safety but also helps reduce downtime and maintenance costs for our clients.
      
      We believe in building long-term relationships with our customers, and this commitment is reflected in our personalized approach to service delivery. Our goal is to contribute to a more efficient, safe, and sustainable shipping industry, helping our clients navigate the challenges of modern maritime operations with confidence. With a solid foundation in the Japanese market and an expanding global presence, NY Corporation is poised for continued growth and success. We invite you to explore our range of products and services, and experience the NY Corporation difference in marine solutions.
      `
    },
    {
      id: 18,
      name: 'NANJING CHUTUO SHIPBUILDING EQUIPMENT CO. LTD',
      logo: '',
      description:`Nanjing Chutuo Shipbuilding Equipment Co.,Ltd , a member of IMPA & ISSA , is a integrated supplier for shipchandlers and wholesalers . The Thaihang Antisplashing tape is popular with all the markets worldwidely due to the favorable price and preferred quality .`
    },
    {
      id: 19,
      name: 'NICOMAR GmbH Maritime Sales Services',
      logo: 'assets/StaticSupplierslogo/NICOMAR GmbH Maritime Sales Services.png',
      description:`Sales
      Our representation of high quality ship equipment enable us to be your trusted
      technical and commercial consultants when you invest in new vessels. We
      understand that careful and considered maker selection is a critical factor to
      guaranteeing shipbuilding quality and our experience of the maritime industry will
      help you to realise your goals.
      
      Services
      For your existing fleet, we provide reliable life-time support for your vessels with
      the supply of genuine spare parts and local stockholding for operationally critical
      spare parts to ensure short delivery times. As a service station for major
      European ports we are able to extend the service network of our Asian partners
      to Europe. Our service engineers are fully trained and certified by the original
      manufacturers enabling us to provide high quality and professional after sales
      support to your fleet.
      
      Consultancy
      We provide consultancy focused on sales and marketing in the maritime industry.
      Our extensive experience with Asian and European shipbuilding and operation
      enable us to develop strategic sales concepts and campaigns, and to be an
      exceptional partner in concept execution and implementation.
      `
    },
    {
      id: 20,
      name: 'Pelagus 3D',
      logo: 'assets/StaticSupplierslogo/Pelagus 3D.png',
      description:`Pelagus 3D is an on-demand digital manufacturing partner for the maritime and offshore industry. A joint venture company of thyssenkrupp and Wilhelmsen, Pelagus 3D leverages on additive manufacturing to ensure the availability of on-demand spare parts. With our incomparable ecosystem of global partners, a secured digital Pelagus platform, and 3D design expertise, Pelagus 3D is focused on enabling our OEM partners to deliver spare parts on-demand to their end-user customers, with minimal interruptions in operations and to the highest level of quality. Pelagus 3D is connecting OEMs, manufacturing partners, and over 4000 participating vessels and oil and gas platforms together to enable on-demand manufacturing globally.`
    },
    {
      id: 21,
      name: 'Pitzner Industrieservice GmbH & Co. KG',
      logo: 'assets/StaticSupplierslogo/Pitzner Industrieservice GmbH & Co. KG.png',
      description:``
    },
    {
      id: 22,
      name: 'Sander Marine GmbH&Co.KG',
      logo: 'assets/StaticSupplierslogo/Sander Marine GmbH&Co.KG.png',
      description:``
    },
    {
      id: 23,
      name: 'Seacon Shipping Group Holdings Ltd',
      logo: 'assets/StaticSupplierslogo/Seacon Shipping Group Holdings Ltd.png',
      description:`Seacon Shipping Group Holdings Limited (Seacon) is an integrated shipping services provider headquartered in the PRC. We endeavour to provide comprehensive shipping solutions to our customers along the value chain of the maritime shipping industry. We have been successful in the provision of ship management and shipping services. We are the first Chinese Shipmanager among the 2023 Lloyd’s list Top 10 Shipmanagers. According to the F&S Report, we ranked the first among ship management services providers headquartered in the PRC in terms of the number of third-party owned vessels under management in 2021. With a combined weight carrying capacity of approximately 1.5 million DWT for our entire controlled vessel fleet, and 23 newbuilding vessels.

      As at March 30th, 2024, we managed 232 vessels in total. We manage various types and sizes of vessels registered under the flag states of major global shipping hubs such as Singapore, Hong Kong, the PRC, Panama, the Marshall Islands and Liberia.
      
      In the mean time, we owned a fleet comprising 30 vessels. With our large and varied fleet of dry bulk carriers, we are able to transport all major kinds of dry bulks for our customers such as iron ore, coal, grain, steel, logs, cement, fertilizer, nickel ore and bauxite. In addition to dry bulk goods, we are also able to transport asphalt, petrochemical products and molten sulphur through our controlled fleet of oil and chemical tankers.
      `
    },
    {
      id: 24,
      name: 'Seago Marine Engineering and Services Limited',
      logo: '',
      description:`Drydocking Repair Services, Marine Stores & Spare Parts Supply
      Based in China
      
      --- Supply marine stores/ materials during DD when urgent need (as local shipchandler)    
      --- Main Engine / Aux Engine Repairs & Overhauling 
      --- AC & provision plant system & Repairs with Compressor Overhauling
      --- FFE & LSA Services , Life Boat Load Test
      --- Navigation Equipment Services (Radio Service & Radar, ECDIS, VHF Repairs)
      --- Heat Exchanger Repair and Fabrication ( Heaters & Coolers,  tube type & Plate type)
      --- Automation control work with electrical parts supply
      --- Marine Boiler Retubing & Repairs (WPS and welders are approved by All Class soceity) 
      --- Diesel Engine Spare Part Recondition (piston crown, exh. valve spindle and seat recondition)
      --- Hatch cover/ Windlass/ Deck Crane hyd system repairs
      --- Hyd motor/ pump to repair, supply 2nd hand or brand new hyd motor/pump for HC, Windlass, Winches, Crane
      --- Grab Repairs & supply spare parts for grab
      --- UTM Services, gas detectors to calibrate
      --- Bow & Stern Thruster Overhauling & Services
      `
    },
    {
      id: 25,
      name: 'Seago Marine Engineering and Services Limited',
      logo: '',
      description:`Seaway was established in 2008, is a premium service provider for marine and offshore industries. The marine stores/provisions, drilling tools and consumables supplied by us are of good quality and well certified to meet the relevant operational requirements.
      Seaway provides “one-stop” warehousing and logistics solution services for spare parts transit in China & Singapore by self-managed warehouses.
      Seaway is committed to research and development of new ship energy-saving technology, transformation of energy-saving new achievements for shipowners.
      `
    },
    {
      id: 26,
      name: 'Seven Seas Group',
      logo: 'assets/StaticSupplierslogo/Seven Seas Group.png',
      description:`Seven Seas is a global maritime services group that specializes in providing general ship supplies, stores, spare parts & leading technical maritime brands through its extensive global network. Founded in 1971, Seven Seas' vision is to be the preferred partner in ship supply through superior service and cost level.`
    },
    {
      id: 27,
      name: 'STr Marine Sp. z o.o.',
      logo: '',
      description:`STr Marine Sp. z o.o.
      Ship Spare Parts Supplier
      
      STr Marine is a team of experienced specialists supplying spare parts suitable for compressors, pumps, separators, on-board equipment and many other ship equipment.
      
      We offer high quality alternative spare parts, of proven quality and origin. The company is managed under ISO 9001:2015 and has 35 years of experience in the maritime market.
      
      We offer spare parts suitable for:
      
      - Air compressors: J.P. Sauer & Sohn, Sperre, Deno, Atlas Copco, Hatlapa;
      - Pumps: Hawmorthy, DESMI, Naniwa, Taiko Kikai;
      - Separators: Alfa Laval, Westfalia, Mitsubishi;
      - Refrigeration compressors: Sabroe, Bock;
      - Automatic filters, FWG, BWTS: Alfa Laval;
      - Deck Equipment: Aker Pusnes;
      - Four stroke diesel engines: Sulzer, types A and Z;
      
      For our regular Clients we offer extensive technical support based on our long term experience, technical knowledge and expanded network of suppliers and partners.
      
      STr Marine Sp. z o.o.
      ul. Piesza 21; 70-663 Szczecin; Poland
      phone: +48 91 444 08 26
      e-mail: marine@str.pl
      www: marine.str.pl
      VAT: PL 8521020251
      `
    },
    {
      id: 28,
      name: 'TURBOMED S.A.',
      logo: 'assets/StaticSupplierslogo/TURBOMED S.A..png',
      description:`TURBOMED SA was founded in 1976 and since then holds a leading position both in the domestic and international market in the shipbuilding sector, particularly in the field of turbocharger services and spare parts for Marine & Navy, Oil & Gas, Power Plant and Auto & truck turbochargers.
      Over the course of 46 years, the company's upward development resulted in the creation of significant number of subsidiary companies and workshops in key ports around the world, providing inspection, overhaul, maintenance, and upgrade services, as well as spare parts for every turbocharger manufacturer and type.
      These service stations are located in: Greece, Piraeus - Romania, Constanta - Africa, Lagos - Cyprus, Limassol - Spain, Algeciras - Turkey, Tuzla - Egypt, Port Said - Sri Lanka, Colombo - Mexico, Veracruz - China, Zhoushan - Azerbaijan, Baku.
      Experience, high expertise, professionalism, consistency, and accountability are some of the principles that allow the company to offer high-quality products and value-added services to customers. Our highly trained technicians are ready to respond at any time, even to the most demanding situations, traveling to any port in the world to provide the best possible service to our clients.
      Since 1995, our company has been an authorized service agent for KBB Turbochargers in Greece, providing turbocharger services for the full range of KBB turbochargers.
      Finally, TURBOMED continues to pursue international expansion and adoption of the latest technological innovations by promoting environmentally friendly business practices, while contributing to sustainable development and a better future for the planet.
      `
    },
    {
      id: 29,
      name: 'TX Marine Messsysteme GmbH',
      logo: '',
      description:`The TX Marine Messsysteme GmbH is family-owned company established in 2013.
      The company founder, Kay Paschen, brings over 30 years of experience to the company and has passed his knowledge to the next generation, the daughter, Nadine Paschen, who will take over the company.
      The company's product portfolio is divided into its own developed and produced products and trading goods. In recent years, the company has established itself as a system provider for measurement technology for everything to do with ship engines.
      The main focus of the company, engine performance monitoring, is the heart of the company. The TORXmeter® shaft power meter and PMImkII electric . indicator are in-house products.
      The company can also offer a variety of products in other areas:
      Performance Software and Hardware
      Flow Measurement (Mass flow, Volume flow)
      non-destructive material testing
      Condition Monitoring (Deflection, Crankshaft, Oil mist, Videoscopes, Oil condition,
      Overspeed tester, Vibration monitoring)
      Calibration equipment (pressure and temperature)
      Emission measurement (permanent and portable)
      Years of experience can also be incorporated in the service sector with the Maintenance and repair of electronic indicator (different types). As well, some new service areas are developed further:
      - Engine diagnostic with measurement data evaluation
      - Data collection
      - Vibration measurement and Analysis
      - Power/Speed tests
      - NDT service, e.g. annual inspection of Scrubber end lines
      - Underwater Inspection via ROV and data analysis via AI
      - Maintenance of permanent installed emission measurement systems, e.g. Marsic
      If there are measuring devices or services that are not listed in the product range it is also no problem. Thanks to the worldwide network that has been built up over many years, we can find a solution for nearly every measurement or service problem.
      The steadily expanding network now includes sales and service partners in various countries around the world, e.g. China, India, South Korea, Turkey and much more.
      Let set sail together!
      `
    },
    {
      id: 30,
      name: 'Wilhelmsen Ships Service GmbH',
      logo: 'assets/StaticSupplierslogo/Wilhelmsen Ships Service GmbH.png',
      description:`As a world-leading maritime solutions provider, Wilhelmsen Ships Service provides marine products, essential consumables, and services for all vessel types. We offer our customers an extensive range of marine products under trusted brand names, including Unitor™, Nalfleet™, Timm™, Unicool™ and Navadan™.

      By constantly developing new solutions for our customers, we are shaping maritime for the future. We are actively working to use our maritime expertise to create greater value and deliver unparalleled customer experience.
      `
    },
  ];