import { Component,Input } from '@angular/core';

@Component({
  selector: 'button-loading',
  template: `
    <button mat-button [matTooltipPosition]="placement" matTooltipClass="mat-tooltip-custom"
      [matTooltip]="toolTip" [type]="type" [disabled]="loading" [ngClass]="{
        loading: loading,
        'mr-5': loading,
        'mat-button': !raised,
        'mat-mini-fab': raised,
        'save-form-button': !loading
      }">
      {{ buttonText }}
    </button>
  `,
  templateUrl: './button-loading.component.html',
  styleUrl: './button-loading.component.css'
})
export class ButtonLoadingComponent {
  @Input('loading') loading!: boolean;
  @Input('btnClass') btnClass!: string;
  @Input('raised') raised: boolean = true;
  @Input('loadingText') loadingText: string = 'Wait...';
  @Input('type') type: 'button' | 'submit' = 'submit';
  @Input('color') color!: 'primary' | 'accent' | 'warn';
  @Input('toolTip') toolTip: string = 'Save';
  @Input('placement') placement: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'below';
}
