import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { SupplierPerformanceComponent } from './supplier-performance/supplier-performance.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';

export const Adminroutes: Routes = [
  {
    path: '',
    component: AdminDashboardComponent,
    children: [
      { path: 'overview', component: DashboardOverviewComponent },
      { path: 'performance', component: SupplierPerformanceComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'settings', component: SettingsComponent },
      { path: '', redirectTo: 'overview', pathMatch: 'full' }, // Default route
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(Adminroutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}