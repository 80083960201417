<mat-card>
  <h3>Supplier Delivery Times</h3>
  <ngx-charts-bar-vertical
  [view]="[700, 400]"
  [scheme]="colorScheme"
  [results]="deliveryTimesData"
  [gradient]="false"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="true"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  [xAxisLabel]="'Suppliers'"
  [yAxisLabel]="'Average Delivery Time (Days)'">
</ngx-charts-bar-vertical>
</mat-card>

<mat-card>
  <h3>Supplier Regional Distribution</h3>
  <ngx-charts-pie-chart
    [view]="[700, 400]"
    [scheme]="colorScheme"
    [results]="regionalDistributionData"
    [gradient]="false"
    [legend]="true"
    [labels]="true"
    [doughnut]="false">
  </ngx-charts-pie-chart>
</mat-card>

<mat-card>
  <h3>Supplier Order Fulfillment Rate</h3>
  <ngx-charts-pie-chart
    [view]="[700, 400]"
    [scheme]="colorScheme"
    [results]="orderFulfillmentData"
    [gradient]="false"
    [legend]="true"
    [labels]="true"
    [doughnut]="true">
  </ngx-charts-pie-chart>
</mat-card>

<mat-card>
  <h3>Supplier Performance Over Time</h3>
  <ngx-charts-line-chart
    [view]="[700, 400]"
    [scheme]="colorScheme"
    [results]="performanceOverTimeData"
    [gradient]="false"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [xAxisLabel]="'Month'"
    [yAxisLabel]="'Performance'">
  </ngx-charts-line-chart>
</mat-card>