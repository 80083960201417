import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'environment'
})
export class EnvironmentPipe implements PipeTransform {

  transform(url: string): string {
    return environment.assetsLink + url;
  }
}
