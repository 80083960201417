<button mat-button [color]="color" class="button-loading {{btnClass}}" 
  matTooltipClass="mat-tooltip-custom" matTooltip="{{toolTip}}" [type]="type" [disabled]="loading" [ngClass]="{
    loading: loading,
    'mr-5': loading,
    'mat-button': !raised,
    'mat-mini-fab': raised,
    'save-form-button': !loading
  }">
  <!-- !below code to be fixed and used in button tag above -->
  <!-- matTooltipPosition="{{placement}}" -->
  <div class="btn-spinner" *ngIf="loading"></div>
  <span *ngIf="!loading">
    <ng-content></ng-content>
  </span>
</button>