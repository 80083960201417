import { Component, OnInit, ViewChild } from "@angular/core";
import { Supplier } from "src/app/shared/models/supplier.model";
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { SupplierService } from "../supplier.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Page } from "src/app/shared/models/page";
import { Observable } from 'rxjs';
import { MatIconRegistry } from "@angular/material/icon";
import { Location } from '@angular/common';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { AutoCompleteDataType } from "src/app/shared/models/settings.model";
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { SettingsService } from "../../settings.service";
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { SupplierDialogComponent } from "../supplier-dialog/supplier-dialog.component";

@Component({
  selector: "app-supplier-detail",
  templateUrl: "./supplier-detail.component.html",
  styleUrls: ["./supplier-detail.component.css"],
})
export class SupplierDetailComponent implements OnInit {
  //#region Variable Initialization
  title: string = "";
  supplierFormObj: Supplier = <Supplier>{};
  @ViewChild("supplierDetails") supplierForm!: NgForm;
  // TradingAddressForm!: FormGroup;
  isButtonloading: boolean = false;
  searchKeyParams: string = '';
  _selectedCountryCode!: string;
  _selectedRegionCode!: string;
  filterFieldCountryNameDisplayFn(_country: AutoCompleteDataType) {
    return _country ? `${_country.name}` : undefined;
  }

  boundGetCountryAutoComplete!: (pageMeta: Page) => Observable<any>;

  redirectRouteCallback!: string | null;
  isAdmin: boolean | undefined;
  //#endregion
  page = new Page();
  isValidateTrue: boolean = false;
  userId: number | string = 0;
  supplierFormData: any;
  isFormSubmitted: boolean = false;
  supplierId: any;
  mpsId: any;
  items!: FormArray;
  emailAddressArray: any = [];
  countryRegions: any = [];
  regionCities: any = [];
  showLoader: boolean = true;
  isAddEdit: boolean = true;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private supplierService: SupplierService,
    private settingsService: SettingsService,
    private ls: LocalStoreService,
    private cookieService: CookieService,
    private _location: Location,
    private snack: MatSnackBar,
    private formBuilder: FormBuilder,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      "add-deck-check",
      sanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsLink}images/plus.svg`)
    );

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.mpsId = navigation.extras.state['mpsId'];
    }

    this.supplierFormData = this.formBuilder.group({
      companyName: [null, Validators.required],
      vendorCode: [{ value: this.mpsId, disabled: true }, Validators.maxLength(255)],
      vsSupplierCode: [{ value: null, disabled: true }, Validators.maxLength(255)],
      firstName: [null, Validators.maxLength(255)],
      lastName: [null, Validators.maxLength(255)],
      contactPerson: [null, Validators.maxLength(255)],
      businessRegisterNumber: [null, Validators.maxLength(255)],
      state: [null],
      mobileNumber1: [null],
      mobileNumber2: [null],
      website: [null, Validators.maxLength(255)],
      emailAddressArray: this.formBuilder.array([]),
      isMaker: [null],
      isOwner: [null],
      isAgent: [null],
      street: [''],
      city: [null, Validators.required],
      region: [null, Validators.required],
      country: [null, Validators.required],
      zipCode: ['']
    });
    // Initial set page number and page size
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.isAddEdit = history.state.isAddEdit;
    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.userId = id;
        this.updateFormPrefill();
      }
      // else {
      //   this.showLoader = false;
      // }
    });
    //Check Logged in User is Admin or Not\
    this.isAdmin = JSON.parse(this.cookieService.get("isAdmin"));
    let supplierId: number | any = this.activatedRoute.snapshot.paramMap.get(
      "supplierId"
    );

    this.redirectRouteCallback = this.activatedRoute.snapshot.paramMap.get(
      "redirectRouteCallback"
    );
    if (
      supplierId &&
      supplierId !== "undefined" &&
      typeof supplierId != undefined
    ) {
      // Update Supplier Request
      this.title = this.redirectRouteCallback || !this.isAdmin
        ? "Supplier"
        : "Update Supplier";
      this.BindForm(supplierId);
    } else {
      this.emailData(0, 'Add')
      this.title = "Add Supplier";
      // this.showLoader = false;
    }

    if (this.userId !== 'all') {
      this.title = 'Update Supplier';
    }

    this.boundGetCountryAutoComplete = this.getCountryFilterKeyDataObservable.bind(this);
  }

  updateFormPrefill() {
    if (this.userId !== 'all' && this.userId !== null && this.userId !== undefined) {
      this.supplierService.getSupplierUpdateDataByUserId(this.userId)
        .then((data) => {
          this.supplierId = data.data[0].supplierid;

          const arrayControl = <FormArray>this.supplierFormData.controls['emailAddressArray'];

          this.emailAddressArray.controls[0].get('emailAddress').setValue(data.data[0].primaryEmail);
          this.emailAddressArray.controls[0].get('id').setValue(data.data[0].emailId);

          for (let i = 1; i < data.data.length; i++) {
            this.emailData(i, 'Add');
            this.emailAddressArray.controls[i].get('emailAddress').setValue(data.data[i].emailAddresses);
            this.emailAddressArray.controls[i].get('id').setValue(data.data[i].emailId);
          }

          this.supplierFormData.patchValue({
            companyName: data.data[0].companyName,
            vsSupplierCode: data.data[0].vsSupplierCode,
            vendorCode: data.data[0].mpsVendorCode,
            firstName: data.data[0].firstName,
            lastName: data.data[0].lastName,
            contactPerson: data.data[0].contactPerson,
            businessRegisterNumber: data.data[0].businessRegisterNumber,
            // address: data.data[0].supplierAddress,
            mobileNumber1: data.data[0].phone1,
            street: data.data[0].street,
            city: data.data[0].city,
            region: data.data[0].region,
            country: data.data[0].country,
            zipCode: data.data[0].zipcode
          });

          
          this.showLoader = false;
        })
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: 0,
      emailAddress: [null, Validators.required],
      isActive: true
    });
  }
  /**
   * Fetch supplier details by supplierId and bind supplier form with supplier details
   * @param _supplierId Supplier Id.
   */
  async BindForm(_supplierId: number) {
    this.showLoader = false;
    this.supplierFormObj = await this.supplierService.getSupplierById(
      _supplierId
    );
    if (this.supplierFormObj) {
      this.supplierFormData.patchValue({
        companyName: this.supplierFormObj.companyName || '',
        vendorCode: this.supplierFormObj.vendorCode || '',
        vsSupplierCode: this.supplierFormObj.vsSupplierCode || '',
        firstName: this.supplierFormObj.firstName || '',
        lastName: this.supplierFormObj.lastName || '',
        contactPerson: this.supplierFormObj.contactPerson || '',
        businessRegisterNumber: this.supplierFormObj.businessRegisterNumber || '',
        // address: this.supplierFormObj.address || '',
        // country: this.supplierFormObj.countryId || '',
        // state: this.supplierFormObj.stateId || '',
        mobileNumber1: this.supplierFormObj.mobileNumber1 || '',
        mobileNumber2: this.supplierFormObj.mobileNumber2 || '',
        website: this.supplierFormObj.website || '',
        isMaker: this.supplierFormObj.isMaker || '',
        isOwner: this.supplierFormObj.isOwner || '',
        isAgent: this.supplierFormObj.isAgent || '',
        street: [''],
        city: [null, Validators.required],
        region: [null, Validators.required],
        country: [null, Validators.required],
        zipCode: ['']
      });
      this.supplierFormObj.supplierContactAddresses.forEach((element, index) => {
        this.emailData(index, 'Add')
      });
      (this.emailAddressArray?.controls ?? []).forEach((element: any, index: any) => {
        element.get('id').setValue(this.supplierFormObj.supplierContactAddresses[index].id);
        element.get('emailAddress').setValue(this.supplierFormObj.supplierContactAddresses[index].emailAddress);
        element.get('isActive').setValue(this.supplierFormObj.supplierContactAddresses[index].isActive);
        if (this.supplierFormObj.supplierContactAddresses[index].isActive) {
          element.get('emailAddress').enable();
        } else {
          element.get('emailAddress').disable();
        }
      });

      // Add initial/first email address control for older data (email not available)
      if (this.emailAddressArray == null || typeof this.emailAddressArray == "undefined"
        || this.emailAddressArray.length == 0) {
        this.emailData(0, 'Add');
      }
    }
    this.showLoader = false;
  }

  /**
   * Submit supplier form data.
   */
  async SubmitForm() {
    if (this.supplierFormData.valid && this.isValidateTrue) {
      this.isFormSubmitted = true;
      this.isButtonloading = true;
      let formData = this.supplierFormData.getRawValue()
      this.supplierFormObj.companyName = formData.companyName;
      this.supplierFormObj.emailAddress1 = formData.emailAddressArray[0].emailAddress;
      // this.supplierFormObj.emailAddress2 = null;
      this.supplierFormObj.businessRegisterNumber = formData.businessRegisterNumber;
      // this.supplierFormObj.address = formData.address
      this.supplierFormObj.contactPerson = formData.contactPerson;
      // this.supplierFormObj.countryId = formData.country
      // this.supplierFormObj.stateId = formData.state
      this.supplierFormObj.supplierContactAddresses = formData.emailAddressArray
      this.supplierFormObj.firstName = formData.firstName
      this.supplierFormObj.isAgent = formData.isAgent
      this.supplierFormObj.isMaker = formData.isMaker
      this.supplierFormObj.isOwner = formData.isOwner
      this.supplierFormObj.lastName = formData.lastName
      this.supplierFormObj.mobileNumber1 = formData.mobileNumber1
      this.supplierFormObj.mobileNumber2 = formData.mobileNumber2
      this.supplierFormObj.vendorCode = formData.vendorCode
      this.supplierFormObj.vsSupplierCode = formData.vsSupplierCode
      this.supplierFormObj.website = formData.website
      this.supplierFormObj.street = formData.street
      this.supplierFormObj.city = formData.city
      this.supplierFormObj.region = formData.region
      this.supplierFormObj.country = formData.country
      this.supplierFormObj.zipCode = formData.zipCode
      let isUpdateRequest =
        this.supplierFormObj.id !== null &&
        typeof this.supplierFormObj.id !== undefined &&
        this.supplierFormObj.id > 0;

      this.supplierFormObj.supplierContactAddresses = this.supplierFormObj.supplierContactAddresses.map((_address) => {
        _address.id = _address?.id ?? null;
        return _address;
      });
      if (isUpdateRequest) {
        this.supplierService
          .updateSupplier(this.supplierId, this.supplierFormObj)
          .then((_supplierDetail) => {
            this.isButtonloading = false;


            this.router.navigateByUrl(
              this.redirectRouteCallback || `/supplier/supplier/updateSupplier`,
              {
                skipLocationChange: this.redirectRouteCallback == null,
              }
            );
            this.closeForm();
          })
          .catch((_httpErrorResponse: HttpErrorResponse) => {
            //console.log(_httpErrorResponse);
            this.isButtonloading = false;
          });
      } else {
        this.supplierService
          .addSupplier(this.supplierFormObj, this.supplierId)
          .then(async (_supplierDetail) => {
            this.isButtonloading = false;
            this.router.navigateByUrl(
              this.redirectRouteCallback || `/supplier/supplier/addSupplier`,
              {
                skipLocationChange: this.redirectRouteCallback == null,
              }
            );
            this.closeForm();
          })
          .catch((_httpErrorResponse: HttpErrorResponse) => {
            //console.log(_httpErrorResponse);
            this.isButtonloading = false;
          });
      }
    } else {
      this.isButtonloading = false;
      return;
    }
  }

  /**
   * Reset or close supplier form.
   */
  closeForm() {
    // this.router.navigateByUrl(this.redirectRouteCallback || "/supplier/supplier");
    this._location.back();
  }
  addEmailData(i: number) {
    let duplicate = this.emailAddressArray.getRawValue().filter((item: any, index: number) => {
      if (index != i) {
        return item.emailAddress == this.emailAddressArray.controls[i].value.emailAddress
      }
      return false;
    });
    if (duplicate.length > 0) {
      this.snack.open(
        'Duplicate supplier contact email addresses are not allowed',
        "OK",
        { duration: 3000, panelClass: "custom-snack" }
      );
      this.emailAddressArray.controls[i].get('emailAddress').setValue(Object.keys(this.supplierFormObj).length > 0 && this.supplierFormObj?.supplierContactAddresses[i]?.emailAddress ? this.supplierFormObj.supplierContactAddresses[i].emailAddress : null)
      return;
    }
  }

  emailData(index: number, type: string, isEdit?: undefined) {
    if (type === 'Delete') {
      if (this.emailAddressArray['controls'][index].controls.emailAddress.value == null || this.emailAddressArray['controls'][index].controls.emailAddress.value == '') {
        const arrayControl = <FormArray>this.supplierFormData.controls['emailAddressArray'];
        arrayControl.removeAt(index);
      } else {
        this.emailAddressArray['controls'][index].get('isActive').setValue(false);
        this.emailAddressArray['controls'][index].get('emailAddress').disable();
      }
    } else if (type === 'Restore') {
      this.emailAddressArray['controls'][index].enable();
      this.emailAddressArray['controls'][index].get('isActive').setValue(true)
    } else if (type === 'Add') {
      this.emailAddressArray = this.supplierFormData.get('emailAddressArray') as FormArray;
      this.emailAddressArray.push(this.createItem());
    }
  }

  checkIfSupplierExists() {
    let formData = this.supplierFormData.getRawValue();
    // const companyName = formData.companyName;
    const emailAddressArr = [];

    for (let emailArrObj of formData.emailAddressArray) {
      if (emailArrObj.isActive)
        emailAddressArr.push(emailArrObj.emailAddress);
    }
    this.showLoader = false;
    this.supplierService.validateSupplierName(emailAddressArr)
      .then((data) => {
        if (data.validationResult === false) {
          this.isValidateTrue = true;
        }
        else {
          const supplierNames: any = [];
          const supplierIdsArr: any = [];

          data.data.map((item: any, index: number) => {
            if(supplierIdsArr.indexOf(item.supplierId) < 0) {
              supplierNames.push({
                companyName: item.companyName,
                supplierId: index,
                emailAddress: item.emailAddress
              });

              supplierIdsArr.push(item.supplierId);
            }
          });

          if (supplierNames.length > 1) {
              const dialogRef = this.dialog.open(SupplierDialogComponent, {
              data: supplierNames
            });

            dialogRef.afterClosed().subscribe(result => {
              // console.log(result);

              const selectedSupplierId = result.result.index;

              this.emailAddressArray.controls[0]
                .get('emailAddress')
                .setValue(data.data[selectedSupplierId].emailAddress1);
              for (let i = 0; i < data.data.length; i++) {
                this.emailData(i, 'Add');
                this.emailAddressArray.controls[i + 1]
                  .get('emailAddress')
                  .setValue(data.data[i].emailAddress);
              }

              this.supplierFormData.patchValue({
                companyName: data.data[selectedSupplierId].companyName,
                vsSupplierCode: data.data[selectedSupplierId].vsSupplierCode,
                vendorCode: data.data[selectedSupplierId].vendorCode,
                firstName: data.data[selectedSupplierId].firstName,
                lastName: data.data[selectedSupplierId].lastName,
                contactPerson: data.data[selectedSupplierId].contactPerson,
                businessRegisterNumber: data.data[selectedSupplierId].businessRegisterNumber,
                mobileNumber1: data.data[selectedSupplierId].mobileNumber1,
                mobileNumber2: data.data[selectedSupplierId].mobileNumber2,
                // emailAddressArray: [data.data[0].emailAddress1],
                street: data.data[selectedSupplierId].street,
                city: data.data[selectedSupplierId].city,
                region: data.data[selectedSupplierId].region,
                country: data.data[selectedSupplierId].country,
                zipCode: data.data[selectedSupplierId].zipcode,
                website: data.data[selectedSupplierId].website
              });
              this.isValidateTrue = false;
              this.snack.open(
                'This supplier is already in our system. Please go ahead and add their information to the Supplier Portal',
                "OK",
                { duration: 3000, panelClass: "custom-snack" }
              );
            });
          }

          else {

            this.emailAddressArray.controls[0]
              .get('emailAddress')
              .setValue(data.data[0].emailAddress1);
            for (let i = 0; i < data.data.length; i++) {
              this.emailData(i, 'Add');
              this.emailAddressArray.controls[i + 1]
                .get('emailAddress')
                .setValue(data.data[i].emailAddress);
            }

            this.supplierFormData.patchValue({
              companyName: data.data[0].companyName,
              vsSupplierCode: data.data[0].vsSupplierCode,
              vendorCode: data.data[0].vendorCode,
              firstName: data.data[0].firstName,
              lastName: data.data[0].lastName,
              contactPerson: data.data[0].contactPerson,
              businessRegisterNumber: data.data[0].businessRegisterNumber,
              mobileNumber1: data.data[0].mobileNumber1,
              mobileNumber2: data.data[0].mobileNumber2,
              // emailAddressArray: [data.data[0].emailAddress1],
              street: data.data[0].street,
              city: data.data[0].city,
              region: data.data[0].region,
              country: data.data[0].country,
              zipCode: data.data[0].zipcode,
              website: data.data[0].website
            });
            this.isValidateTrue = false;
            this.snack.open(
              'This supplier is already in our system. Please go ahead and add their information to the Supplier Portal',
              "OK",
              { duration: 3000, panelClass: "custom-snack" }
            );
            // this.showLoader = false;
          }
        }
        this.showLoader = false;
      })
      .catch((err) => {
        console.log(err);
        // this.showLoader = false;
      })
  }

  getCountryFilterKeyDataObservable(pageMeta: Page): Observable<any> {
    pageMeta.serachParam =
      pageMeta.serachParam || pageMeta.serachParam == ''
        ? pageMeta.serachParam
        : this.searchKeyParams;
    this.searchKeyParams = pageMeta.serachParam;
    return this.countryDataWithSearch(pageMeta.serachParam)
  }

  countryDataWithSearch(searchText: any) {
    const regex = new RegExp(searchText, 'i');
    return new Observable<any>(observer => {
      let data = JSON.parse(this.ls.getItem('countries'))
      const filteredData = data.filter((item: any) => regex.test(item.name));
      const dataResult = { rows: filteredData }
      observer.next(dataResult);
      observer.complete();
    });
  }

  getTradingAddressCountryControl(): any {
    return this.supplierFormData.get('country') as FormControl;
  }

  onCountrySelected(value: AutoCompleteDataType) {
    this.supplierFormData.get('region')?.reset();
    this.supplierFormData.get('region')?.setErrors(null);
    this._selectedCountryCode = value.code ? value.code : ''
    this.getRegionFilterKeyDataObservable(this._selectedCountryCode).subscribe(data => {
      this.countryRegions = data.rows
    })
  }

  onRegionSelected(value: any) {
    this.supplierFormData.get('city')?.reset();
    this.supplierFormData.get('city')?.setErrors(null);
    this._selectedRegionCode = value.iso ? value.iso : '';
    this.getCityFilterKeyDataObservable(this._selectedRegionCode, this._selectedCountryCode).subscribe(data => {
      this.regionCities = data.rows
    })
  }

  getRegionFilterKeyDataObservable(countryCode: string) {
    return this.settingsService.getAllRegionsByFliterKeyValue(
      countryCode
    );
  }

  getCityFilterKeyDataObservable(regionCode: string, countryCode: string) {
    return this.settingsService.getAllCitiesByFliterKeyValue(
      regionCode,
      countryCode
    );
  }
}