import { Component } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {

  // colorScheme = {
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#1E90FF', '#32CD32', '#FF4500']
  // };
  colorScheme='cool';

  // Example data for Supplier Delivery Times
  deliveryTimesData = [
    { "name": "ADAMAR INTERNATIONAL MARITIME SERVICES INC", "value": 10 },
    { "name": "Arthur Friedrichs Industriebedarf GmbH", "value": 15 },
    { "name": "Breckle Matratzenwerk Weida GmbH", "value": 8 },
    { "name": "Changchun Shipping PTE Ltd", "value": 10 },
    { "name": "CIS Elektrotechnik GmbH", "value": 12 },
    { "name": "Desin Marine & Industry Services Ltd.", "value": 10 },
    { "name": "Drew Marine", "value": 5 },
    { "name": "ELECTRICA SA Cable Electrical Material Supplier", "value": 12 },
    { "name": "Fast Marine Services Denizcilik Ltd", "value": 10 },
    { "name": "Fuji Trading Co., Ltd", "value": 15 },
    { "name": "Hidramar Group", "value": 10 },
    { "name": "KET Marine International B.V.", "value": 10 },
    { "name": "Kloska GmbH, Uwe", "value": 15 },
    { "name": "Ligabue S.p.A.", "value": 15 },
    { "name": "LUTZ Elevators", "value": 5 },
    { "name": "MEC Safety Systems GmbH", "value": 5 },
    { "name": "N.Y. Co. Ltd", "value": 8 },
    { "name": "NANJING CHUTUO SHIPBUILDING EQUIPMENT CO. LTD", "value": 8 },
    { "name": "NICOMAR GmbH Maritime Sales Services", "value": 10 },
    { "name": "Pelagus 3D", "value": 10 },
    { "name": "Pitzner Industrieservice GmbH & Co. KG", "value": 15 },
    { "name": "Sander Marine GmbH&Co.KG", "value": 10 },
    { "name": "Seacon Shipping Group Holdings Ltd", "value": 8 },
    { "name": "Seago Marine Engineering and Services Limited", "value": 10 },
    { "name": "Seaway Marine & Offshore Service Company Ltd", "value": 10 },
    { "name": "Seven Seas Group", "value": 10 },
    { "name": "STr Marine Sp. z o.o.", "value": 10 },
    { "name": "TURBOMED S.A.", "value": 15 },
    { "name": "TX Marine Messsysteme GmbH", "value": 8 },
    { "name": "Wilhelmsen Ships Service GmbH", "value": 10 }
    // Add more suppliers as needed
  ];

  // Example data for Supplier Regional Distribution
  regionalDistributionData = [
    { name: 'Europe', value: 15 },
    { name: 'Asia', value: 10 },
    { name: 'North America', value: 5 },
    { name: 'South America', value: 3 },
    { name: 'Africa', value: 2 },
  ];

  // Example data for Supplier Order Fulfillment Rate
  orderFulfillmentData = [
    { name: "ADAMAR INTERNATIONAL MARITIME SERVICES INC", value: 95 },
    { name: "Arthur Friedrichs Industriebedarf GmbH", value: 90 },
    { name: "Breckle Matratzenwerk Weida GmbH", value: 98 },
    { name: "Changchun Shipping PTE Ltd", value: 85 },
    // Add more suppliers as needed
  ];

  // Example data for Supplier Performance Over Time
  performanceOverTimeData = [
    {
      name: "ADAMAR INTERNATIONAL MARITIME SERVICES INC",
      series: [
        { name: "January", value: 80 },
        { name: "February", value: 82 },
        { name: "March", value: 85 },
        { name: "April", value: 88 },
      ]
    },
    {
      name: "Arthur Friedrichs Industriebedarf GmbH",
      series: [
        { name: "January", value: 75 },
        { name: "February", value: 78 },
        { name: "March", value: 80 },
        { name: "April", value: 82 },
      ]
    },
    // Add more suppliers as needed
  ];
}