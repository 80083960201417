<!-- <div class="header-topnav mat-elevation-z2">
  <div class="container">
    <div class="topnav">
      <div class="topbar-branding">
        <img [src]="'images/ihm-app-logo-small.png' | environment" alt="VS" class="app-logo">
      </div>

      <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index;">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
              <mat-icon>{{item.icon}}</mat-icon>
              {{item.name | translate}}
            </a>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{i}}" class="toggle"><mat-icon>{{item.icon}}</mat-icon> {{item.name |
                translate}}</label>
              <a matRipple><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</a>
              <input type="checkbox" id="drop-{{i}}" />
              <ul>
                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                  <a matRipple routerLink="{{itemLvL2.state ? '/'+itemLvL2.state : ''}}"
                    *ngIf="itemLvL2.type !== 'dropDown'">
                    <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>
                    {{itemLvL2.name | translate}}
                  </a>

                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                    <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon> {{itemLvL2.name |
                      translate}}</a>
                    <input type="checkbox" id="drop-{{i}}{{j}}" />
                    <div style="display: flex; flex-direction: column;">
                      <ul class="background-lv3"
                        style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); width: 1200px; height: 50vh; overflow:scrollable; list-style-type: none; padding: 0px;">
                        <li class="custom-navbar-lv3" *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                          <a matRipple
                            routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                            <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                            {{itemLvL3.name | translate}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <span fxFlex></span>

      <div class="navbar-divider">
        <button style="border: none; background-color: transparent;" mat-menu-item (click)="jwtAuth.signout()">
          <mat-icon>power_settings_new</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div> -->

<div class="mat-toolbar">
  <div class="mat-toolbar-row">
    <div class="logo-container">
      <a href="./home.html">
        <img src="https://mps-cdn-dev.s3.eu-west-1.amazonaws.com/assets/images/sentinels-logo.png" alt="VS">
      </a>
    </div>
    
    <span class="example-spacer"></span>

    <div *ngFor="let item of menuItems; let i = index;">
      <a *ngIf="item.type === 'link'" matRipple routerLink="/{{item.state}}">
        <button class="menu-item">
          <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
          {{item.name | translate}}
        </button>
      </a>

      <div class="dropdown" id="productsDropdown" *ngIf="item.type === 'dropDown'">
        <button class="menu-item">
          <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
          {{item.name | translate}}
        </button>
        <div class="dropdown-content">
          <div *ngFor="let itemLvL2 of item.sub; let j = index;" matRipple>
            <a matRipple routerLink="{{itemLvL2.state ? '/'+itemLvL2.state : ''}}">
              <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>
              {{itemLvL2.name | translate}}
            </a>

            
            
            <!-- <div *ngIf="itemLvL2.type === 'dropDown'">
              <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
              <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon> {{itemLvL2.name |
                translate}}</a>
              <input type="checkbox" id="drop-{{i}}{{j}}" />
              <div style="display: flex; flex-direction: column;">
                <ul class="background-lv3"
                  style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); width: 1200px; height: 50vh; overflow:scrollable; list-style-type: none; padding: 0px;">
                  <li class="custom-navbar-lv3" *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                    <a matRipple
                      routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                      <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                      {{itemLvL3.name | translate}}
                    </a>
                  </li>
                </ul>
              </div>
            </div> -->

            <!-- <div *ngIf="itemLvL2.type === 'dropDown'">
              <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
              <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon> {{itemLvL2.name |
                translate}}</a>
              <input type="checkbox" id="drop-{{i}}{{j}}" />
              <div style="display: flex; flex-direction: column;">
                <ul class="background-lv3"
                  style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); width: 1200px; height: 50vh; overflow:scrollable; list-style-type: none; padding: 0px;">
                  <li class="custom-navbar-lv3" *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                    <a matRipple
                      routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                      <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                      {{itemLvL3.name | translate}}
                    </a>
                  </li>
                </ul>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>

    <button style="border: none; background-color: transparent;" mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </div>
</div>