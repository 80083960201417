import { Injectable } from '@angular/core';
import { UserMenuRight } from '../../../shared/models/user-menu-right.model';
import { WebApiService } from '../../../shared/services/web-api.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  constructor(
    private webApiService: WebApiService,
    private cookieService: CookieService,
    ) { }

  /**
   * Get all users list.
   */
  getAllMenus(): Promise<any[]> {
    let apiUrl = "/users/menus/getMenus";
    let reqBody='';
    return this.webApiService.getRequest<any[]>(apiUrl);
  }

  /**
   * Update any existing user details by user id.
   * @param _userId To be updated user id.
   * @param _userDetail User form data.
   */
  updateUserRights(_userId: number, _userDetail: any): Promise<any> {
    let apiUrl = `/users/updateMenuRights/${_userId}`;
    return this.webApiService.putRequest<any>(apiUrl, _userDetail);
  }

  /**
   * Add new user.
   * @param _userDetail User form data.
   */
  addUserRights(_userId: number, _userDetail: any): Promise<any> {
    let apiUrl = `/users/updateMenuRights/${_userId}`;
    return this.webApiService.postRequest<any>(apiUrl, _userDetail);
  }
  
  /**
   * Get all menu rights detail by user id.
   * @param _userId User Id.
   */
  getMenuRightsByUserId(_userId: number): Promise<UserMenuRight[]> {
    let apiUrl = `/users/getMenuRights/${_userId}`;
    return this.webApiService.getRequest<UserMenuRight[]>(apiUrl);
  }

  getMenuRightsByUserAndRole(_userId: number, _roleId: number): Promise<any> {
    //let apiUrl = `/users/getMenuRightsByUserAndRole/${_userId}/${_roleId}`;
    let apiUrl = `/users/getMenuRightsByUserAndRole/${_userId}/${_roleId}`;
    return this.webApiService.getRequest<any>(apiUrl);
  }

  setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict') {
    // Delete all duplicate cookies
    if (this.cookieService.check(name)) {
      this.cookieService.delete(name);
    }
    this.cookieService.set(name, value, expires, path, domain, secure, sameSite);
  }
  
}
