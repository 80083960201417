import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { tap } from 'rxjs/operators';
import { RoutePartsService } from "../services/route-parts.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../environments/environment";
import { FullLoaderService } from "../services/full-loader.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /** Add all domains here, where you would not like to interceptor to intercept the API call */
  excludedDomainList: string[] = [
    "ihm-cdn-dev.varuna-sentinels.com",
    "ihm-cdn-prod.varuna-sentinels.com",
  ];

  /** Add all UI routes here, where you would not like to interceptor to intercept the API response */
  excludedResponseRoutesList: string[] = [
    "/sessions/signin2",
    "/sessions/signup2",
    "/sessions/signin",
    "/sessions/signup",
  ];

  constructor(
    private cookieService: CookieService,
    private routePartsService: RoutePartsService, 
    private activatedRoute: ActivatedRoute,
    private _fullLoaderService: FullLoaderService,
    private snack: MatSnackBar, 
    private router: Router) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let exclude: boolean = false;
    this.excludedDomainList.forEach(x => exclude = req.url.includes(x)? true : exclude);

    let changedReq: HttpRequest<any> = req;

    if(!exclude){
      const tokenId = this.cookieService.get("tokenId");
      const tokenHash = this.cookieService.get("tokenHash");
      const _userId = this.cookieService.get("userId");
      let _shipId: string;
  
      // Do not send shipId in request header if we are not in ship screen
      if (this.routePartsService.generateRouteParts(this.activatedRoute.snapshot).length > 0) {
        if (this.routePartsService.generateRouteParts(this.activatedRoute.snapshot)[0].url == 'ship') {
          if (this.cookieService.check("shipId")) {
            _shipId = this.cookieService.get("shipId");
          } else {
            _shipId = "null";
          }
        } else {
          _shipId = "null";
        }
      }
  
      if (tokenId && tokenHash) {
        let headers = req.headers.set("id", tokenId)
          .set("hash", tokenHash);
        if (_userId) {
          headers = headers.set("userid", _userId);
        }
        // if (_shipId) {
        //   headers = headers.set("shipid", _shipId);
        // }
        headers = headers.set("x-api-key", environment.apiKey);
        changedReq = req.clone({ headers });
      } else if (tokenId && (tokenHash == null || tokenHash == "undefined" || typeof tokenHash == undefined || tokenHash == "")) {
        let headers = req.headers.set("id", tokenId);
        headers = headers.set("x-api-key", environment.apiKey);
        changedReq = req.clone({ headers });
      } else {
        let headers = req.headers.set("x-api-key", environment.apiKey);
        changedReq = req.clone({ headers });
      }
    }
    
    return next.handle(changedReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // Handle success response here
          }
        },
        error => {
          this._fullLoaderService.hideLoader();
          if (error instanceof HttpErrorResponse) {
            // Handle error response here
            if (!this.excludedResponseRoutesList.includes(this.router.url)) {
              switch (error.status) {
                // Keep adding different error code cases which are common to all requests
                case 403: {
                  this.cookieService.deleteAll("/");

                  // Do not open multiple snack bars! Since many requests pass through interceptor
                  if (!(this.snack._openedSnackBarRef)) {
                    let errorMessage = error.error?.errors ? error.error.errors[0]?.message : null;
                    this.snack.open(`${errorMessage || "Something went wrong!"}`, "OK", {
                      duration: 3000,
                      panelClass: "custom-snack",
                    });
                  }

                  // this.snack._openedSnackBarRef.afterDismissed().subscribe(() => {
                  //   this.router.navigateByUrl(`/sessions/signin2`);
                  // });
                  break;
                }
                default: {
                  //console.log("Token Interceptor: ", error);
                  if (!(this.snack._openedSnackBarRef)) {
                    let errorMessage = error.error?.errors ? error.error.errors[0]?.message : null;
                    this.snack.open(`${errorMessage || "Something went wrong!"}`, "OK", {
                      duration: 3000,
                      panelClass: "custom-snack",
                    });
                  }
                  break;
                }
              }
            } else {
              //console.log("Token interceptor error response handling skipped due to excluded route.");
            }
          }
        }
      )
    );
  }

}
